<template lang="pug">
div(
  v-if='isIntegrationEnabled("geolocation") || $storyblok.isEditing.value'
  :class='{ relative: $storyblok.isEditing.value }'
)
  div(
    class='flex items-center justify-between cursor-pointer lg:p-0'
    :class='classes'
    data-testid='locationDisplay'
    @click='openLocationPopup'
  )
    div(class='flex items-center text-sm')
      LocationIcon(class='w-4 h-4 mr-1 fill-current')
      //- Show some text in Storyblok when geolocation is disabled
      span {{ isIntegrationEnabled('geolocation') ? $geolocation.formattedLocation : 'Geolocation Disabled' }}

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-[50]')
</template>

<script setup lang="ts">
import LocationIcon from '@/assets/location.svg?component'
const { isIntegrationEnabled } = useUtils()

interface GeoTextWidget extends Widget {
  textColor: FontColor
}

const { data } = defineProps<{
  data: GeoTextWidget
}>()

const { $geolocation } = useNuxtApp()

const classes = computed(() => {
  return generateFontColorClass(data?.textColor)
})

function openLocationPopup() {
  $geolocation.openModal()
}
</script>
