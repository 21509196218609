import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "aria-label": "Close",
  viewBox: "0 0 10 10"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M10 9.5 5.5 5 10 .5 9.5 0 5 4.5.5 0 0 .5 4.5 5 0 9.5l.5.5L5 5.5 9.5 10z" }, null, -1)
  ])))
}
export default { render: render }