import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "12",
  height: "12",
  viewBox: "0 0 32 32"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M14 9.5c0-.8.7-1.5 1.5-1.5h1c.8 0 1.5.7 1.5 1.5v1c0 .8-.7 1.5-1.5 1.5h-1c-.8 0-1.5-.7-1.5-1.5zM20 24h-8v-2h2v-6h-2v-2h6v8h2z" }, null, -1),
    _createElementVNode("path", { d: "M16 0a16 16 0 1 0 0 32 16 16 0 0 0 0-32m0 29a13 13 0 1 1 0-26 13 13 0 0 1 0 26" }, null, -1)
  ])))
}
export default { render: render }