export const useRecommendationsStore = defineStore('Recommendations', () => {
  const rootState = useRootStore()
  const fitmentState = useFitmentStore()
  const { $sitewideConfig } = useNuxtApp()
  const { getApiUrl } = useUrls()
  const { isRealtruckBrand } = useUtils()

  const state: Ref<RecommendationState> = ref({
    similar: null,
    related: null,
    recent: null,
    recommended: null,
  })

  async function getRecommendations({ types, productLineSlug }: Options) {
    if (types.length === 0) return

    const url = getApiUrl('recommendations')
    const response = await $fetch<RecommendationsResponse>(url, {
      query: {
        makeSlug: fitmentState.makeSlug,
        modelSlug: fitmentState.modelSlug,
        year: fitmentState.year,
        bedSlug: fitmentState.bedSlug,
        bodySlug: fitmentState.bodySlug,
        engineSlug: fitmentState.engineSlug,
        permId: rootState.permId,
        productLineSlug,
        types: types.join(','),
      },
    })

    if (response) {
      const types = ['similar', 'related', 'recent', 'recommended']

      types.forEach((type) => {
        const recs = response[type as keyof RecommendationState]
        // If Non-Transactional is enabled, filter out non-transactional products
        if ($sitewideConfig.config.nonTransactionalEnabled && recs?.results) {
          recs.results = recs.results.filter((rec) => isRealtruckBrand(rec.brand))
        }

        if (recs && recs.results.length > 0) state.value[type as keyof RecommendationState] = recs || null
      })
    }
  }

  return {
    state,
    getRecommendations,
  }
})

interface Options {
  types: string[]
  productLineSlug: string
}
