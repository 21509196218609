<template lang="pug">
div(
  v-if='recommendations'
  :class='{ "py-8 md:py-12": !overridePadding }'
  data-cnstrc-recommendations
  :data-cnstrc-recommendations-pod-id='podId'
  :data-cnstrc-result-id='recommendations.resultId'
  :data-cnstrc-num-results='recommendations.numberOfResults'
)
  slot(name='title' :default-title='recommendations.title')
    p(v-if='recommendations.title' class='text-gray-darkest text-2xl font-bold leading-none pb-6 lg:text-3xl') {{ recommendations.title }}

  Carousel(container-class='gap-x-3' :arrow-position='arrowPosition' :arrow-width='arrowWidth')
    div(
      v-for='(result, index) in recommendations.results'
      :key='`${result.data?.variation_id}-${index}`'
      class='w-[275px]'
      data-cnstrc-item='Recommendation'
      :data-cnstrc-item-id='result.data?.productLineSlug'
      :data-cnstrc-item-name='result.value'
      :data-cnstrc-item-variation-id='result.data?.variation_id'
      :data-cnstrc-strategy-id='result.strategy?.id'
      :data-cnstrc-item-price='result.data?.salePrice / 100'
    )
      ProductListing(:listing='result.data' is-carousel :is-schema-on='false')
</template>

<script setup lang="ts">
import type { RecommendationsResponse } from '@constructor-io/constructorio-client-javascript'

const {
  podId,
  itemId,
  overridePadding = false,
  arrowPosition = 'INLINE',
  arrowWidth = 'DEFAULT',
} = defineProps<{
  podId: string
  itemId?: string
  overridePadding?: boolean
  arrowPosition?: 'INLINE' | 'BOTTOM'
  arrowWidth?: 'FULL' | 'DEFAULT'
}>()

const { $sitewideConfig, $constructor } = useNuxtApp()
const route = useRoute()
const constructorFitment = useConstructorFitment()

interface RecommendationData {
  title?: string
  results: RecommendationsResponse['response']['results']
  resultId: string
  numberOfResults: number
}

const recommendations = shallowRef<RecommendationData>()

watch(
  () => route.fullPath,
  () => {
    getRecommendations()
  }
)

onMounted(() => {
  getRecommendations()
})

async function getRecommendations() {
  const filters: Record<string, any> = {}

  const fitmentFilters = constructorFitment.getFitmentAttributes()
  if (fitmentFilters) {
    filters[fitmentFilters.name] = fitmentFilters.value
  }

  // NonTransactional Needs to only show RT Products
  if ($sitewideConfig.config.nonTransactionalEnabled) {
    const brands = $sitewideConfig.config.madeByRealTruckBrands.data ?? []
    if (brands.length > 0) filters.brand = brands
  }

  try {
    const data = await $constructor.client.recommendations.getRecommendations(podId, {
      numResults: 12,
      itemIds: itemId,
      filters,
    })

    if (data.response.results?.length === 0) {
      recommendations.value = undefined
    } else {
      recommendations.value = {
        title: data.response.pod?.display_name,
        results: data.response.results ?? [],
        resultId: data.result_id,
        numberOfResults: data.response.total_num_results ?? 0,
      }
    }
  } catch (error) {
    recommendations.value = undefined
  }
}
</script>
