<template lang="pug">
FormKit(
  v-bind='$attrs'
  data-role='none'
  :classes='classes'
  :errors='errors'
  :label='label'
  :maxlength='maxLength'
  :name='name'
  :placeholder='placeholder'
  :type='type'
  :validation='validation'
  :validation-label='validationLabel'
  :validation-messages='validationMessages'
  :value='value'
  @blur='onBlur'
  @focus='onFocus'
)
</template>

<script setup lang="ts">
const emit = defineEmits(['blur', 'focus'])
/*
  ===
  FormKit Properties
  ===
  classes: Object
    The classes prop is similar to the section-key class prop except it allows setting classes on all
    sections at the same time.
    See: https://formkit.com/essentials/styling#classes-prop#classes-prop
  ---
  errors: String[]
    Array of strings to show as error messages on this field.
    See: https://formkit.com/essentials/inputs#explicit-errors
  ---
  validation: String
    The validation rules to be applied to the input.
    See: https://formkit.com/essentials/validation
  ---
  validation-label: String
    Determines what label to use in validation error messages, by default it uses the label prop if
    available, otherwise it uses the name prop.
    See: https://formkit.com/essentials/validation#custom-messages
*/

interface Props {
  classes?: {}
  errors?: string[]
  label?: string
  maxLength?: number
  name?: string
  value?: string
  placeholder?: string
  type?: 'text' | 'email' | 'tel' | 'password' | 'number'
  validation?: string
  validationLabel?: string
  validationMessages?: {}
}

withDefaults(defineProps<Props>(), {
  classes: () => {
    return {}
  },
  errors: () => [],
  label: '',
  maxLength: 50,
  name: '',
  placeholder: '',
  type: 'text',
  value: undefined,
  validation: '',
  validationLabel: '',
  validationMessages: () => {
    return {}
  },
})

function onBlur() {
  emit('blur')
}

function onFocus() {
  emit('focus')
}
</script>
