<template lang="pug">
div
  template(v-if='isIntegrationEnabled("constructor")')
    slot(name='constructor')

  template(v-else)
    slot(name='original')
</template>

<script setup lang="ts">
const { isIntegrationEnabled } = useUtils()
</script>
