<template lang="pug">
div
  svg(:class='logoOptions.class' class='fill-current w-full')
    use(:href='`/images/same-day-sprites.svg#${logoOptions.sprite}`')
</template>

<script setup lang="ts">
const { $sitewideConfig } = useNuxtApp()
const { sameDayShippingTextColor } = useSameDayShippingDisplay()
const { isIcon = false, isWhite = false } = defineProps<{
  isIcon?: boolean
  isWhite?: boolean
}>()

const logoOptions = computed(() => {
  const obj = {
    sprite: '',
    class: [],
  } as any

  if (isWhite) obj.class.push('text-white')
  else obj.class.push(sameDayShippingTextColor)

  if (isIcon) {
    obj.sprite = 'same-day-icon'
    obj.class.push('h-8')
    obj.class.push('w-8')
  } else {
    obj.sprite =
      $sitewideConfig.config.sameDayShippingMode === 'RAPIDSHIP' ? 'same-day-rapidship' : 'same-day-quickship'
    obj.class.push('h-[10px]')
  }

  return obj
})
</script>
