export function generateTextAlignmentClasses(mobile: Alignment, tablet: Alignment, desktop: Alignment) {
  /**
   * Tailwind Text Alignment Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * text-left md:text-left lg:text-left
   * text-center md:text-center lg:text-center
   * text-right md:text-right lg:text-right
   */

  return getScreenClassPrefix(
    generateTextAlignmentClass(mobile),
    generateTextAlignmentClass(tablet),
    generateTextAlignmentClass(desktop)
  )
}

function generateTextAlignmentClass(alignment: Alignment) {
  if (!alignment || alignment === 'INHERIT') return ''
  return `text-${alignment.toLowerCase()}`
}

export function generateFlexAlignmentClasses(mobile: Alignment, tablet: Alignment, desktop: Alignment) {
  /**
   * Tailwind Flex Justify Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * justify-start md:justify-start lg:justify-start
   * justify-center md:justify-center lg:justify-center
   * justify-end md:justify-end lg:justify-end
   *
   */

  return getScreenClassPrefix(
    generateFlexAlignmentClass(mobile),
    generateFlexAlignmentClass(tablet),
    generateFlexAlignmentClass(desktop)
  )
}

export function generateFlexAlignmentClass(alignment: Alignment) {
  switch (alignment) {
    case 'LEFT':
    case 'TOP':
      return 'justify-start'

    case 'CENTER':
    case 'MIDDLE':
      return 'justify-center'

    case 'RIGHT':
    case 'BOTTOM':
      return 'justify-end'

    case 'BETWEEN':
      return 'justify-between'

    case 'AROUND':
      return 'justify-around'

    case 'EVENLY':
      return 'justify-evenly'

    default:
      return ''
  }
}

export function generateGapClasses(mobile: GapSize, tablet: GapSize, desktop: GapSize) {
  /**
   * Tailwind Grid Gap Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * gap-0 md:gap-0 lg:gap-0
   * gap-2 md:gap-2 lg:gap-2
   * gap-4 md:gap-4 lg:gap-4
   * gap-8 md:gap-8 lg:gap-8
   * gap-16 md:gap-16 lg:gap-16
   * gap-24 md:gap-24 lg:gap-24
   *
   */

  return getScreenClassPrefix(generateGapClass(mobile), generateGapClass(tablet), generateGapClass(desktop))
}

function generateGapClass(gap: GapSize) {
  switch (gap) {
    case 'NONE':
      return 'gap-0'

    case 'XSMALL':
      return 'gap-2'

    case 'SMALL':
      return 'gap-4'

    case 'MEDIUM':
      return 'gap-8'

    case 'LARGE':
      return 'gap-16'

    case 'XLARGE':
      return 'gap-24'

    // If we don't support the layout then return nothing
    default:
      return ''
  }
}
