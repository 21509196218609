export default function () {
  const { $sitewideConfig } = useNuxtApp()
  const config = useRuntimeConfig()
  const route = useRoute()

  const state = useState<string | string[]>('disableIntegrations')
  const disabledByRuntime = useState<string[]>('disabledByRuntime', () => [])

  /**
   * Detects if the integration is enabled using configs and the disable query string
   * @returns true if the integration is enabled
   */
  function isIntegrationEnabled(integration: Integration) {
    const disableByQuery = route.query['disableIntegration']
    if (disableByQuery) state.value = disableByQuery.toString()

    if (isDisabledInDev(integration) || isDisabledInPreview(integration) || isDisabledByRuntime(integration))
      return false

    // disabled via site config
    if ($sitewideConfig.config && !$sitewideConfig.config[`${integration}Enabled`]) return false

    // disabled by query string
    if (state.value === 'all' || state.value?.includes(integration)) return false

    return true
  }

  function disableIntegration(integration: Integration) {
    disabledByRuntime.value.push(integration)
  }

  function isDisabledByRuntime(integration: Integration) {
    return disabledByRuntime.value.includes(integration)
  }

  function isDisabledInDev(integration: Integration) {
    if (!isDev()) return false

    switch (integration) {
      case 'bugsnag':
      case 'forter':
      case 'ketch':
      case 'segment':
      case 'speedcurve':
        return true
    }

    return false
  }

  // It's possible this could return false instead of true if they are in preview and go to another page and THAT page calls this -- meh, they aren't supposed to be navigating in preview anyway
  // We can update whenever the storyblok integration is created if really needed
  function isDisabledInPreview(integration: Integration) {
    if (!route.query['_storyblok']) return false

    // detect preview?
    switch (integration) {
      case 'forter':
      case 'liveperson':
      case 'wisepops':
      case 'segment':
      case 'ketch':
      case 'speedcurve':
        return true
    }
    return false
  }

  /**
   * Detects if the container is running in production
   * @returns true if the container is using production configs
   */
  function isProduction() {
    return config.public.mode === 'production'
  }

  /**
   * Detects if the server is running in dev mode
   * @returns true if the server is running on your local dev env
   */
  function isDev() {
    return import.meta.env.MODE !== 'production'
  }

  function isRealtruckBrand(brandName: string) {
    if (!brandName || !$sitewideConfig.config.madeByRealTruckEnabled) return false

    return !!(
      $sitewideConfig.config.madeByRealTruckBrands?.data.includes(brandName) ||
      $sitewideConfig.config.madeByRealTruckOnlineOnlyBrands?.data.includes(brandName)
    )
  }

  return {
    isIntegrationEnabled,
    isProduction,
    isDev,
    isRealtruckBrand,
    disableIntegration,
  }
}
