<template lang="pug">
div(class='h-full')
  UniversalLink(:link='data.link' tracking-event-type='LinkCard' :open-new-window='data.linkTarget === "NEW_WINDOW"')
    div(class='font-bold fill-current' :class='headerClasses')
      | {{ data.headerText }}
      svg(
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 14 12'
        style='enable-background: new 0 0 14 12'
        xml:space='preserve'
        width='21'
        height='18'
        class='inline-block ml-3'
      )
        path(
          d='M8 12c-.3 0-.5-.1-.7-.3-.4-.4-.4-1 0-1.4L10.6 7H1c-.6 0-1-.4-1-1s.4-1 1-1h9.6L7.3 1.7c-.4-.4-.4-1 0-1.4s1-.4 1.4 0l5 5c.1.1.2.2.2.3.1.1.1.3.1.4 0 .1 0 .3-.1.4 0 .1-.1.2-.2.3l-5 5c-.2.2-.4.3-.7.3z'
        )

    div(:class='contentClasses') {{ data.contentText }}
</template>

<script setup lang="ts">
interface LinkCardWidget extends Widget {
  contentColor: FontColor
  contentSize: FontSize
  contentText: string
  headerColor: FontColor
  headerSize: FontSize
  headerSpace: 'SMALL' | 'MEDIUM' | 'LARGE'
  headerText: string
  link: string
  linkTarget: string
}

const { data } = defineProps<{
  data: LinkCardWidget
}>()

const contentClasses = computed(() => {
  return [generateFontColorClass(data.contentColor), generateFontSizeClass(data.contentSize)]
})

const headerClasses = computed(() => {
  const classes = [generateFontColorClass(data.headerColor), generateFontSizeClass(data.headerSize)]

  switch (data.headerSpace) {
    case 'SMALL':
      classes.push('pb-2')
      break
    case 'MEDIUM':
      classes.push('pb-4')
      break
    case 'LARGE':
      classes.push('pb-6')
  }

  return classes
})
</script>
