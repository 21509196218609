<template lang="pug">
div
  slot
</template>

<script setup lang="ts">
const { searchContext, index = null } = defineProps<{
  searchContext: any
  index?: string
}>()

provide('$searchContext', index ? searchContext.indexes.find((i) => i.key === index) : searchContext)
</script>
