<template lang="pug">
div(:class='spacingClasses')
  CmsEditorTabs(
    :data='[ { name: "Form", clickHandler: () => toggleTab(false) }, { name: "Success", clickHandler: () => toggleTab(true) }, ]'
  )

  template(v-if='isSuccessful')
    template(v-for='widget in data.successWidgets' :key='widget._uid')
      slot(:data='widget')

  FormKit(
    v-else
    :id='data._uid'
    v-slot='{ state: { valid } }'
    type='form'
    :actions='false'
    :incomplete-message='false'
    :name='`subscribeForm-${data._uid}`'
    @submit='save'
  )
    template(v-for='widget in data.formWidgets' :key='widget._uid')
      div(
        v-if='widget.component === "subscribe-form-item"'
        v-bind='$storyblok.bindStoryblokBridgeData(widget)'
        :class='{ relative: $storyblok.isEditing.value }'
      )
        template(v-if='widget.type === "NAME"')
          BaseFormInput(
            label='First Name'
            name='first_name'
            :validation='widget.isRequired === "true" ? "required" : ""'
          )
          BaseFormInput(
            label='Last Name'
            name='last_name'
            :validation='widget.isRequired === "true" ? "required" : ""'
          )

        BaseFormInputEmail(
          v-else-if='widget.type === "EMAIL"'
          v-model='payload.email'
          label='Email'
          name='email'
          :validation='widget.isRequired === "true" ? "required" : ""'
        )

        template(v-else-if='widget.type === "PHONE"')
          //- Is Required to be Accepted if they provide a phone number
          BaseFormCheckbox(
            v-if='$sitewideConfig.config.newsletterSmsEnabled'
            v-model='payload.subscribeSms'
            name='subscribeSms'
            :label='$sitewideConfig.config.newsletterSmsText'
          )
          //- Is Required IF it is set up to be OR if they check the SMS checkbox
          BaseFormInputPhone(
            v-if='payload.subscribeSms || !$sitewideConfig.config.newsletterSmsEnabled'
            v-model='payload.phone'
            label='Phone'
            name='phone'
            :validation='widget.isRequired === "true" || payload.subscribeSms ? "required" : ""'
          )

          div(v-if='payload.subscribeSms' class='text-left') By opting-in to promotional SMS/texts, you agree to receive recurring or automated promotional and personalized text messages (e.g. cart reminders, new product announcements) from {{ $sitewideConfig.config?.siteName }}. Consent is not a condition of any purchase from {{ $sitewideConfig.domain }}. Message frequency varies. Message and data rates may apply. View #[a(href='/terms-and-conditions/' target='_blank') Terms and Conditions] and #[a(href='/privacy-policy/' target='_blank') Privacy Policy].
        template(v-else-if='widget.type === "FITMENT"')
          p(v-if='isFitmentDirty && !isFitmentValid' class='text-danger mb-2') Valid fitment is required!

          FitmentForm(:show-optionals='false' form-name='subscribeWidget' @is-fitment-valid='setFitmentValidation')

        div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')

      slot(v-else :data='widget')

    BaseButton(
      type='submit'
      label='Save'
      size='MEDIUM'
      :is-waiting='isSubmitting'
      :is-disabled='!valid || $storyblok.isEditing.value'
      class='pt-2'
    ) Submit
</template>

<script setup lang="ts">
const { $storyblok, $uiEvents, $emailTracker } = useNuxtApp()
const fitmentDisplayStore = useFitmentDisplayStore()

interface SubscribeFormWidget extends Widget {
  formWidgets: FormWidget[]
  successWidgets: Widget[]
  mobileSpacing: GapSize
  tabletSpacing: GapSize
  desktopSpacing: GapSize
  formName: string
}

interface FormWidget extends Widget {
  type: string
  isRequired: string
}

const { data } = defineProps<{
  data: SubscribeFormWidget
}>()

const isSuccessful: Ref<boolean> = ref(false)
const isFitmentDirty: Ref<boolean> = ref(false)
const isFitmentValid: Ref<boolean> = ref(false)
const isSubmitting: Ref<boolean> = ref(false)

interface Payload {
  email: string
  firstName: string
  lastName: string
  phone: string
  subscribeSms: boolean
}
const payload: Ref<Payload> = ref({
  email: '',
  firstName: '',
  lastName: '',
  phone: '',
  subscribeSms: false,
})

onMounted(() => {
  // Autofill the email if we know it
  payload.value.email = $emailTracker.getEmail() || ''
})

function toggleTab(bool: boolean) {
  isSuccessful.value = bool
}

const spacingClasses = computed(() => {
  return generateGapClasses(data.mobileSpacing, data.tabletSpacing, data.desktopSpacing)
})

const isFitmentRequired = computed(() => {
  const fitment = data.formWidgets.find((widget) => {
    return widget.type === 'FITMENT'
  })

  if (!fitment) return false
  return fitment.isRequired === 'true'
})

function setFitmentValidation(val: boolean) {
  isFitmentValid.value = val
}

async function save() {
  // Double click protection
  if (isSubmitting.value) return

  isSubmitting.value = true
  isFitmentDirty.value = true // We can now show an error if the fitment is not valid

  // Check if Fitment is required and provided
  if (isFitmentRequired.value && !isFitmentValid.value) {
    isSubmitting.value = false
    return
  }
  // If we have a full fitment, submit it
  // Note: We do not pass Fitment data, as that is handled by the normal fitment events.
  if (isFitmentValid.value) await fitmentDisplayStore.saveFitment()

  // Cleanup strings
  const name = `${payload.value.firstName} ${payload.value.lastName}`.trim()
  const email = payload.value.email.trim()
  const phone = payload.value.phone.trim()
  // Create object for Segment
  const eventData = {
    formName: data.formName,
    ...(email && { email }),
    ...(name && { name }),
    ...(phone && { phone }),
  }

  $uiEvents.$emit('newsletterFormSubmitted', eventData)
  $uiEvents.$emit('newsletterSubscribed', eventData)
  // if SMS Checkbox is checked send the event
  if (payload.value.subscribeSms) {
    $uiEvents.$emit('newsletterSubscribedSms', eventData)
  }

  isSuccessful.value = true
  isSubmitting.value = false
}
</script>
