/* eslint-disable no-extra-boolean-cast */
// this function's job is to make sure we update the user's YMM if the one that appears in the url is different than the current state
export default defineNuxtRouteMiddleware(async (to) => {
  // since to.meta is a nuxty array of metas from page comps, we have to look at the meta from matched.
  const meta = to.matched[to.matched.length - 1].meta
  const fitmentStore = useFitmentStore()
  const displayStore = useFitmentDisplayStore()
  const { $algolia } = useNuxtApp()

  if (to.params.makeSlug) {
    // if the M/MM/YMM is diff from current, setup the session
    if (
      to.params.year != fitmentStore.$state.year ||
      to.params.makeSlug != fitmentStore.$state.makeSlug ||
      to.params.modelSlug != fitmentStore.$state.modelSlug
    ) {
      $algolia.searchContexts.fitment.clearRefinements()

      // if we have a model, filter by model because we'll automatically have a make
      if (to.params.modelSlug) $algolia.searchContexts.fitment.addRefinements('modelSlug', to.params.modelSlug)
      if (to.params.makeSlug) $algolia.searchContexts.fitment.addRefinements('makeSlug', to.params.makeSlug)
      if (to.params.year) $algolia.searchContexts.fitment.addRefinements('year', to.params.year)

      await $algolia.searchContexts.fitment.search()

      if ($algolia.searchContexts.fitment.state.value.resultCount === 0) {
        throw createError({
          statusCode: 404,
          message: `Not found - fitment lookup failed (${to.fullPath})`,
          fatal: import.meta.client,
        })
      }

      const [fitmentResult] = $algolia.searchContexts.fitment.state.value.results

      const obj: any = {
        make: fitmentResult.make,
        makeSlug: fitmentResult.makeSlug,
        ...(to.params.year && { year: `${fitmentResult.year}` }), // converting to string
      }

      if (to.params.modelSlug) {
        if (to.params.modelSlug !== fitmentResult.modelSlug) {
          // from Nuxt 2 - this doesn't seem to make sense
          throw createError({
            statusCode: 404,
            message: `Not found - fitment model slug mismatch (${to.fullPath})`,
            fatal: true,
          })
        }

        obj.model = fitmentResult.model
        obj.modelSlug = fitmentResult.modelSlug
      }

      fitmentStore.update(obj)

      if (import.meta.client) displayStore.initSelects()
    }
  } else if (meta && meta.isFitable && fitmentStore.$state.makeSlug) displayStore.clearFitment()
})
