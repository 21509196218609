<template lang="pug">
div
  button(
    type='button'
    v-bind='$attrs'
    data-testid='treeMenuSlideLink'
    class='flex justify-between items-center gap-x-2 w-full text-black text-left'
    @click='openMenu'
  )
    p(class='truncate') {{ text }}
    img(src='@/assets/chevronRight.svg' class='flex-none w-4 h-4 stroke-current')

  client-only
    transition(
      enter-active-class='transition ease-in-out duration-200 transform'
      enter-from-class='-translate-x-full'
      enter-to-class='translate-x-0'
      leave-active-class='transition ease-in-out duration-200 transform'
      leave-from-class='translate-x-0'
      leave-to-class='-translate-x-full'
    )
      div(v-show='isOpen' class='fixed z-10 top-0 left-0 w-[280px] h-screen overflow-y-scroll bg-gray-lighter')
        button(
          type='button'
          class='flex items-center justify-center gap-x-1 pl-3 pr-5 h-14 w-full bg-white border-b border-gray-light font-bold text-black'
          @click='closeMenu'
        )
          img(src='@/assets/chevronLeft.svg' class='flex-none w-4 h-4 stroke-current')
          p(class='mx-auto text-center truncate') {{ text }}

        div(class='pb-36')
          slot
</template>

<script setup lang="ts">
defineOptions({
  inheritAttrs: false,
})
defineProps<{
  text: string
}>()

const isOpen = ref(false)
function openMenu() {
  isOpen.value = true
}

function closeMenu() {
  isOpen.value = false
}
</script>
