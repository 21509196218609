export default function wireProductListeners() {
  const segment = useSegmentHelper()
  const { formatCartProduct, formatProductData, getFitment, isQuickship } = useSegmentProductsHelper()
  const { $uiEvents, $sitewideConfig } = useNuxtApp()
  const rootStore = useRootStore()
  const cart = useCart()

  $uiEvents.$on('productViewed', emitProductViewed)
  $uiEvents.$on('productShippingViewed', emitCustomerShippingExpectations)
  $uiEvents.$on('addToCart', emitAddToCart)
  $uiEvents.$on('addGroupsToCart', emitAddGroupedToCart)
  $uiEvents.$on('removeFromCart', emitRemoveFromCart)

  $uiEvents.$on('backInStockSubscribed', emitBackInStockSubscribed)

  $uiEvents.$on('productListClicked', emitProductListClicked)
  $uiEvents.$on('productListViewed', emitProductListViewed)
  $uiEvents.$on('productListFiltered', emitProductListFiltered)

  async function emitAddToCart() {
    if (!cart.lastItem.value) return

    // Format the data
    const product = formatCartProduct(cart.lastItem.value)

    segment.track('Product Added', {
      cart_id: rootStore.permId,
      ...product,
    })
  }

  // Husky Splitter A/B Test Add To Cart Event
  async function emitAddGroupedToCart(products: any[]) {
    for (let i = 0; i < products.length; i++) {
      const product = products[i]
      // Find this Product inside the cart
      const addedProduct = cart.state.value.items.find((item: any) => product.sku === item.sku)
      if (addedProduct) {
        // Format the base product data
        const formattedProduct = formatCartProduct(addedProduct)
        // Set quantity to what was just added not what is in the cart
        formattedProduct.quantity = product.qty
        // They want one event per item added
        segment.track('Product Added', {
          cart_id: rootStore.permId,
          isGrouped: true, // Tells them this was part of a group add to cart
          ...formattedProduct,
        })
      }
    }
  }

  function emitRemoveFromCart(item: any) {
    if (!item) return
    const product = formatCartProduct(item)
    segment.track('Product Removed', { cart_id: rootStore.permId, ...product })
  }

  async function emitProductViewed(data: any) {
    const { product, isGrouped } = data
    if (!product) return

    const viewedProductObj = formatProductData(product)

    segment.track('Product Viewed', {
      ...viewedProductObj,
      ...(isGrouped && { isGrouped }),
    })
  }

  interface CustomerShippingExpectationsEvent {
    product: ProductLine
    shipping: Shipping
  }
  function emitCustomerShippingExpectations(data: CustomerShippingExpectationsEvent) {
    const productData = formatProductData(data.product)

    segment.track('Customer Shipping Expectations', {
      ...productData,
      ...data.shipping,
      edd_min: data.shipping.estimatedDeliveryDate,
      quickship: $sitewideConfig.config.sameDayShippingEnabled && data.shipping.shipMethod === 'EXPEDITED',
      // This event uses the existance of this field to determine if its a same day
      // I'm providing new data above so they can eventually change this to use the new format
      shipping_same_day_message: data.shipping.shipDate,
    })
  }

  function emitBackInStockSubscribed({ skuSlug, email }) {
    const primaryFitment = getFitment()

    segment.track('Back In Stock', {
      skuSlug,
      email,
      primaryFitment,
    })
  }

  function emitProductListClicked({ algoliaObj, position, indexName, queryID }: any) {
    const productObj = getAlgoliaProduct(algoliaObj, position + 1)
    segment.track('Product Clicked', {
      ...(indexName && { index: indexName }),
      ...(queryID && { queryID }),
      ...productObj,
    })
  }

  // when a product square is viewed (currently just the whole result, not on viewport)
  function emitProductListViewed({ results, indexName, queryID }: any) {
    segment.track('Product List Viewed', {
      list_id: 'main',
      ...(indexName && { index: indexName }),
      ...(queryID && { queryID }),
      // category - We get individual products, but no main category to put here
      products: getAlgoliaProducts(results),
    })
  }

  function emitProductListFiltered({ filters, sort, products }: any) {
    // Determine Filters
    const filterObj = Object.keys(filters).reduce((acc, attributeName) => {
      const values = filters[attributeName].map((attribute: any) => ({
        type: attributeName,
        value: attribute.value,
      }))
      acc.push(...values)
      return acc
    }, [])

    // Determine Sort from indexName
    let sortType = null
    let sortDirection = null
    if (sort) {
      sortType = sort.includes('price') ? 'price' : 'recommended'
      if (sort.includes('_desc')) sortDirection = 'desc'
      else if (sort.includes('_asc')) sortDirection = 'asc'
    }

    segment.track('Product List Filtered', {
      list_id: 'main',
      filters: filterObj,
      ...(sort && {
        sorts: [
          {
            type: sortType,
            value: sortDirection,
          },
        ],
      }),
      products: getAlgoliaProducts(products),
    })
  }

  function getAlgoliaProducts(algoliaObjs: any, useProductLineSlug = false) {
    return algoliaObjs.map((product, index) => {
      return getAlgoliaProduct(product, index + 1, useProductLineSlug)
    })
  }

  function getAlgoliaProduct(product: any, position = 1, useProductLineSlug = false) {
    const isQuickshipable = isQuickship(product)
    const primaryFitment = getFitment()

    return {
      objectID: product.objectID,
      product_id: useProductLineSlug ? product.productLineSlug : product.productId,
      sku: product.skuSlug,
      category: getCategoryTreeSlugFromAlgolia(product.categories),
      name: product.productLineName,
      brand: product.brandSlug,
      price: product.salePrice / 100,
      quantity: 1,
      position,
      ...(isQuickshipable !== null && { quickship: isQuickshipable }),
      ...(primaryFitment && primaryFitment),
    }
  }

  // since cats are super weird in Algolia, this finds the primary and constructs the right string
  function getCategoryTreeSlugFromAlgolia(algoliaCategories: any) {
    // find the cat obj that's primary
    const catObj = algoliaCategories.find((catObj) => {
      // eslint-disable-next-line eqeqeq
      return catObj.primary == true
    })

    // shouldn't happen
    if (catObj === undefined) return undefined

    // get last cat level since it has the full breadcrumb
    let lastLevel = 0

    for (let i = 4; i >= 0; i--) {
      if (catObj[`lvl${i}`]) {
        lastLevel = i
        break
      }
    }

    return catObj[`lvl${lastLevel}`].slug.split('|').join('/')
  }
}
