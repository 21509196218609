// TODOLATER: Drills use Slug while Fitment uses value. We should probably normalize
// them one way of the other
interface MakeDrill {
  text: string
  isDisabled?: boolean
  isHidden?: boolean
  value?: string
  slug?: string
}

export default function (makes: MakeDrill[], featuredMakes: string[]) {
  // Early out if we have less than 16 makes ("Select Make" needs to be accounted for as well)
  // Or if we don't have any featured makes (could be an empty array)
  if (makes.length < 17 || !featuredMakes || featuredMakes.length === 0) return

  // List of top makes IN ORDER,
  // "Select Make" should be the first value so we don't have to re-order it later
  const topMakes = ['select make', ...featuredMakes]

  // Sort makes
  makes.sort((a, b) => {
    const aValue = a.text?.toLowerCase() // lowercase to normalize data just in case
    const bValue = b.text?.toLowerCase()

    if (topMakes.indexOf(aValue) === -1) return 1
    if (topMakes.indexOf(bValue) === -1) return -1
    return topMakes.indexOf(aValue) - topMakes.indexOf(bValue)
  })

  // Count how many top makes there were so we can get the index of the last item
  const lastIndex = makes.reduce((count, make) => {
    if (topMakes.includes(make.text.toLowerCase())) count++
    return count
  }, 0)

  // Splice in a separator based on the last item index.
  makes.splice(lastIndex, 0, {
    text: '----------------------',
    value: '-2', // Add "value" for fitment
    slug: '-2', // Add "slug" for order control options
    isDisabled: true,
    isHidden: false,
  })
}
