<template lang="pug">
div(:class='!$storyblok.isEditing.value ? "h-0" : ""')
  div(v-if='$storyblok.isEditing.value' class='bg-info p-2 text-white rounded') AB Test: {{ data.experimentKey }}
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const amplitude = useAmplitude()

interface AbTestWidget extends Widget {
  experimentKey: string
}

const { data } = defineProps<{
  data: AbTestWidget
}>()

if (data.experimentKey) {
  amplitude.onReady(
    {
      experimentId: data.experimentKey,
      callback: (variant) => {
        const actions = variant?.payload?.data
        if (actions) {
          actions.forEach((action: any) => {
            const devices = action.devices || []
            if (action.show) {
              $storyblok.show(action.show, ...devices)
            }
            if (action.hide) {
              $storyblok.hide(action.hide, ...devices)
            }
          })
        }
      },
    },
    true
  )
}
</script>
