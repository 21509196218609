<template lang="pug">
div
  slot(:results='results' :result-count='resultCount')
    template(v-if='resultCount === 0')
      slot(name='empty')

    template(v-else)
      slot(name='results' :results='results' :result-count='resultCount')
        template(v-for='(result, index) in results')
          slot(name='result' :result='result' :result-count='resultCount' :index='index')
</template>

<script setup lang="ts">
const $searchContext = inject('$searchContext', null)

const results = computed(() => {
  return $searchContext.state.value.results
})

const resultCount = computed(() => {
  return $searchContext.state.value.resultCount ?? 0
})
</script>
