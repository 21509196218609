import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 13.1 16"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M0 5.8C.3 2.5 3.1 0 6.4 0h.3C10 0 12.8 2.5 13 5.8c.1 1.8-.4 3.5-1.5 4.9l-3.8 4.6c-.6.8-1.8.8-2.4 0l-3.8-4.6C.4 9.4-.1 7.6 0 5.8M6.5 3c-2 0-3.7 1.7-3.7 3.7s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8S8.6 3 6.5 3" }, null, -1)
  ])))
}
export default { render: render }