<template lang="pug">
footer(v-if='footerData' id='Footer')
  CmsEditorTabs(
    v-if='isFooterStory'
    :data='[ { name: "Standard", clickHandler: () => setIsSimple(false) }, { name: "Simple", clickHandler: () => setIsSimple(true) }, ]'
  )

  CmsStoryblok(v-if='isSimpleMode' :content='footerData' section-key='footerSimple')
  CmsStoryblok(v-else :content='footerData' section-key='footerStandard')
</template>

<script setup lang="ts">
const { isSimple = false } = defineProps<{ isSimple?: boolean }>()
const { $sitewideConfig, $storyblok } = useNuxtApp()
const isSimpleToggle = ref(false)

const { data: previewData } = await useAsyncData('footer-data', async () => {
  if (!$storyblok.isPreview.value || $storyblok.contentType.value !== 'footer') return null

  const preview = await $storyblok.getPreviewStory<FooterStory>()
  return preview?.story.content ?? null
})

const footerData = computed(() => {
  return previewData.value || $sitewideConfig.footer
})

function setIsSimple(bool: boolean) {
  isSimpleToggle.value = bool
}

const isSimpleMode = computed(() => {
  if ($storyblok.isEditing.value) return isSimpleToggle.value
  return isSimple
})

const isFooterStory = computed(() => {
  return $storyblok.contentType.value === 'footer'
})
</script>
