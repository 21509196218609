<template lang="pug">
div(class='flex flex-wrap' :class='[alignmentClasses, spacingClasses]')
  template(v-for='button in data.buttons' :key='button._uid')
    CmsWidgetButtonItem(
      :data='button'
      :mobile-is-full-width='data.mobileIsFullWidth'
      :tablet-is-full-width='data.tabletIsFullWidth'
      :desktop-is-full-width='data.desktopIsFullWidth'
    )
</template>

<script setup lang="ts">
interface ButtonWidget extends WidgetAlignment {
  buttons: ButtonItem[]
  mobileIsFullWidth: 'INHERIT' | StringBoolean
  tabletIsFullWidth: 'INHERIT' | StringBoolean
  desktopIsFullWidth: 'INHERIT' | StringBoolean
  mobileSpacing: GapSize
  tabletSpacing: GapSize
  desktopSpacing: GapSize
}

const { data } = defineProps<{
  data: ButtonWidget
}>()

const alignmentClasses = computed(() => {
  return generateFlexAlignmentClasses(data.mobileAlignment, data.tabletAlignment, data.desktopAlignment)
})

const spacingClasses = computed(() => {
  return generateGapClasses(data.mobileSpacing, data.tabletSpacing, data.desktopSpacing)
})
</script>
