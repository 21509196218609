export default defineNuxtPlugin(async () => {
  const query = useRoute().query
  const config = useRuntimeConfig()
  const { isDev, isProduction } = useUtils()

  const { data, error } = await useAsyncData('sitewideConfig', async () => {
    let host = useRequestHeader('host')!.replace(/:\d+/, '')

    if (isDev()) {
      // If mode is set to production set domain to realtruck.com otherwise default to staging.realtruck.com
      host = isProduction() ? 'realtruck.com' : 'staging.realtruck.com'
      // Override the domain if domain is set in the config
      if (config.domain) host = config.domain
    }

    const fetchData = await $fetch<SitewideResponse>(`/api/${host}/sitewide`, {
      query: { timeTravel: query.timeTravel },
    })

    return {
      config: fetchData.config?.content,
      header: fetchData.header?.content,
      sitewideBanner: fetchData.sitewideBanner,
      footer: fetchData.footer?.content,
      seo: fetchData.seo,
      styles: fetchData.styles,
      channelId: fetchData.channelId,
      domain: host,
      sitePrefix: fetchData.sitePrefix,
      linkBar: fetchData.linkBar?.content,
      navbar: fetchData.navbar,
    }
  })

  if (error.value) throw createError(`Sitewide data error! Error: ${error.value}`)
  else if (!data.value?.config) throw createError(`Sitewide data invalid! Data: [${JSON.stringify(data.value)}]`)

  const navbar = shallowRef(data.value?.navbar)

  onNuxtReady(async () => {
    const log = useLogger('05.sitewide') // needs to be here vs top
    try {
      const navbarData = await $fetch(`/api/${data.value?.domain}/sitewide/navbar`)

      if (!navbarData) throw new Error('Navbar data not found')

      if (data.value?.navbar) navbar.value = navbarData
    } catch (error) {
      log.error('NAVBAR_ERROR: fetching navbar', { error })
    }
  })

  return {
    provide: {
      sitewideConfig: {
        ...data.value,
        navbar,
      },
    },
  }
})
