// This composable can only be used in a client context
export default function () {
  const { $sitewideConfig, $cognito, $uiEvents, $overlay } = useNuxtApp()
  const rootStore = useRootStore()
  const urls = useUrls()
  const { isIntegrationEnabled } = useUtils()
  const notifications = useNotifications()

  const cartUrl = urls.getApiUrl('cart')
  const cartApi = $fetch.create({
    baseURL: cartUrl,
    headers: {
      'x-site': $sitewideConfig.sitePrefix,
    },
  })

  const customers = useLocalStorage<Customer[]>('customers', [])
  const customerId = useSessionStorage('customerId', '')

  const customer = computed(() => {
    return getCustomer(customerId.value)?.customer
  })

  // This is used in the cart controller plugin to set the active customer
  // if we got a customer id from the url
  function setActiveCustomer(id: string) {
    const customerToSet = getCustomer(id)

    // We can only set the active customer if the customer exists in our state
    if (!customerToSet) return

    // Set the active customer id since we found a matching customer
    customerId.value = id
  }

  function getCustomer(id: string) {
    const index = customers.value.findIndex((customer) => customer.id === id)

    if (index === -1) return

    return {
      customer: customers.value[index],
      index,
    }
  }

  async function createCustomer(name: string, leadSource: CartLeadSource) {
    const resp = await cartApi<{ id: string }>('/', {
      method: 'POST',
      body: {
        leadSource,
        customer: {
          permId: rootStore.originalPermId,
        },
      },
    })

    customers.value.push({
      id: self.crypto.randomUUID(),
      cartId: resp.id,
      name,
      leadSource,
    })
  }

  // This is currently only used when we have a loaded customer.
  // If we need to be able to edit the customer from the customer manager list then
  // we will need to update this function so we don't set the customerId
  async function updateCustomer(id: string, data: Partial<Omit<Customer, 'id'>>) {
    const customer = getCustomer(id)

    // If we couldn not find the customer to update then return
    if (!customer) return

    // Build the updated customer record by merging the existing customer record with the new data
    const updatedCustomer = { ...customer.customer, ...data }

    // If we have a cart id and we have a lead source to update
    if (updatedCustomer.cartId && data.leadSource) {
      await cartApi(`/${updatedCustomer.cartId}`, {
        method: 'PUT',
        body: {
          leadSource: updatedCustomer.leadSource,
        },
      })
    }

    // Save the updated customer record
    customers.value.splice(customer.index, 1, updatedCustomer)
  }

  async function deleteCustomer(id: string) {
    const customer = getCustomer(id)

    // If we couldn not find the customer to delete then return
    if (!customer) return

    // Delete the customers cart
    try {
      if (customer.customer.cartId) {
        await cartApi(`/${customer.customer.cartId}`, {
          method: 'DELETE',
        })
      }

      // Delete the customer record
      customers.value.splice(customer.index, 1)
    } catch (error) {
      notifications.addError('Unable to delete customer', error?.data?.msg)
    }
  }

  function openPaylinkModal() {
    $overlay.open('customerManagerPaylink')
  }

  interface SendPaylinkToCustomerRequest {
    email?: string
    phone?: string
  }
  async function sendPaylinkToCustomer(id: string, data: SendPaylinkToCustomerRequest) {
    const customer = getCustomer(id)

    if (!customer) return

    const resp = await cartApi<{ code: string; id: string }>(`/${customer.customer.cartId}/paylink`, {
      method: 'POST',
      headers: {
        Authorization: isIntegrationEnabled('cognito') ? `Bearer ${await $cognito.getToken()}` : '',
      },
      body: {
        ...data,
      },
    })

    $uiEvents.$emit('paylinkSent', { cartId: resp.id, ...data })
  }

  return {
    customers,
    customer,
    setActiveCustomer,
    createCustomer,
    updateCustomer,
    deleteCustomer,
    openPaylinkModal,
    sendPaylinkToCustomer,
  }
}

export interface Customer {
  id: string
  cartId: string
  name: string
  fitmentData?: Fitment
  leadSource: CartLeadSource
}
