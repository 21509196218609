<template lang="pug">
component(:is='componentData.component' v-if='data && renderWidget' v-bind='componentData.props')
  template(#default='{ data: widget, spaceLeft: widgetSpaceLeft, ...thisWidgetProps }')
    CmsWidget(v-if='widget' :data='widget' :space-left='widgetSpaceLeft || spaceLeft' :widget-props='thisWidgetProps')
</template>

<script setup lang="ts">
import {
  CmsWidgetAbTest,
  LazyCmsWidgetAccordion,
  CmsWidgetAuthor,
  CmsWidgetButton,
  CmsWidgetCartIcon,
  CmsWidgetColumn,
  CmsWidgetConstrain,
  CmsWidgetFitment,
  CmsWidgetGeoText,
  CmsWidgetGlobalContentSection,
  CmsWidgetIframe,
  CmsWidgetImage,
  CmsWidgetImageBullet,
  CmsWidgetImageCard,
  CmsWidgetImageCarousel,
  LazyCmsWidgetInstallernet,
  CmsWidgetJotForm,
  CmsWidgetLevel,
  CmsWidgetLinkCard,
  CmsWidgetPayPalMessage,
  LazyCmsWidgetReadMore,
  CmsWidgetRecommendations,
  CmsWidgetSection,
  LazyCmsWidgetSearchWrapper,
  LazyCmsWidgetSearchInput,
  LazyCmsWidgetSearchDisplay,
  CmsWidgetSiteSearch,
  CmsWidgetSpacer,
  CmsWidgetSubscribeForm,
  CmsWidgetSubscribeInline,
  CmsWidgetSvgWidget,
  LazyCmsWidgetTable,
  LazyCmsWidgetTags,
  CmsWidgetText,
  CmsWidgetVideo,
  CmsWidgetVideoBanner,
  CmsWidgetVideoButton,
  CmsWidgetVisibility,
  LazyCmsWidgetYotpoSiteReviews,
  LazyCmsWidgetYotpoSocialImages,
  ProtexWidget,
} from '#components'

const { $storyblok } = useNuxtApp()

interface Widgets extends Widget {
  backgroundColor?: BackgroundColor
}

const props = defineProps<{
  data: Widgets
  spaceLeft?: Record<string, any>
  widgetProps?: Record<string, any>
}>()

const renderWidget = computed(() => {
  return componentData.value.component && $storyblok.isVisible(props.data)
})

const componentData = computed(() => {
  const widgetOptions: Record<string, any> = {
    component: null,
    props: {
      // Widget Classes
      class: [],
      // ID for the widget, used for scrolling
      ...(props.data.id && { id: props.data.id }),
      // Adds the storyblok editor click events
      ...$storyblok.bindStoryblokBridgeData(props.data),
      // Props that can be passed from the parent widget into its children.
      ...props.widgetProps,
    },
  }

  let addWidgetDefaultClasses = true

  switch (props.data.component) {
    case 'ab-test-widget':
      widgetOptions.component = CmsWidgetAbTest
      widgetOptions.props.data = props.data
      break
    case 'accordion-widget':
      widgetOptions.component = LazyCmsWidgetAccordion
      addWidgetDefaultClasses = false
      widgetOptions.props.data = props.data
      break
    case 'author-widget':
      widgetOptions.component = CmsWidgetAuthor
      break
    case 'button-widget':
      widgetOptions.component = CmsWidgetButton
      widgetOptions.props.data = props.data
      break
    case 'cart-icon-widget':
      widgetOptions.component = CmsWidgetCartIcon
      widgetOptions.props.data = props.data
      break
    case 'column-widget':
      widgetOptions.component = CmsWidgetColumn
      widgetOptions.props.spaceLeft = props.spaceLeft
      widgetOptions.props.data = props.data
      break
    case 'constrain-widget':
      widgetOptions.component = CmsWidgetConstrain
      widgetOptions.props.spaceLeft = props.spaceLeft
      widgetOptions.props.data = props.data
      break
    case 'fitment-widget':
      widgetOptions.component = CmsWidgetFitment
      break
    case 'geo-text-widget':
      widgetOptions.component = CmsWidgetGeoText
      widgetOptions.props.data = props.data
      break
    case 'global-content-section':
      widgetOptions.component = CmsWidgetGlobalContentSection
      widgetOptions.props.data = props.data
      break
    case 'iframe-widget':
      widgetOptions.component = CmsWidgetIframe
      widgetOptions.props.data = props.data
      break
    case 'image-widget':
      widgetOptions.component = CmsWidgetImage
      widgetOptions.props.spaceLeft = props.spaceLeft
      widgetOptions.props.data = props.data
      break
    case 'image-bullet-widget':
      widgetOptions.component = CmsWidgetImageBullet
      widgetOptions.props.data = props.data
      break
    case 'image-card-widget':
      addWidgetDefaultClasses = false
      widgetOptions.component = CmsWidgetImageCard
      widgetOptions.props.spaceLeft = props.spaceLeft
      widgetOptions.props.data = props.data
      break
    case 'image-carousel-widget':
      widgetOptions.component = CmsWidgetImageCarousel
      widgetOptions.props.data = props.data
      break
    case 'installernet-widget':
      widgetOptions.component = LazyCmsWidgetInstallernet
      widgetOptions.props.data = props.data
      break
    case 'jotform-widget':
      widgetOptions.component = CmsWidgetJotForm
      widgetOptions.props.data = props.data
      break
    case 'level-widget':
      widgetOptions.component = CmsWidgetLevel
      widgetOptions.props.data = props.data
      break
    case 'link-card-widget':
      widgetOptions.component = CmsWidgetLinkCard
      widgetOptions.props.data = props.data
      break
    case 'paypal-message-widget':
      widgetOptions.component = CmsWidgetPayPalMessage
      widgetOptions.props.data = props.data
      break
    case 'protex-widget':
      widgetOptions.component = ProtexWidget
      widgetOptions.props.data = props.data
      break
    case 'read-more-widget':
      widgetOptions.component = LazyCmsWidgetReadMore
      widgetOptions.props.data = props.data
      break
    case 'recommendations-widget':
      widgetOptions.component = CmsWidgetRecommendations
      widgetOptions.props.data = props.data
      break
    case 'section':
      addWidgetDefaultClasses = false
      widgetOptions.component = CmsWidgetSection
      widgetOptions.props.data = props.data
      break
    case 'search-section-content':
    case 'search-section-product':
      widgetOptions.component = LazyCmsWidgetSearchWrapper
      widgetOptions.props.data = props.data
      break
    case 'search-display':
      widgetOptions.component = LazyCmsWidgetSearchDisplay
      widgetOptions.props.data = props.data
      break
    case 'search-input':
      widgetOptions.component = LazyCmsWidgetSearchInput
      widgetOptions.props.data = props.data
      break
    case 'site-search-widget':
      widgetOptions.component = CmsWidgetSiteSearch
      widgetOptions.props.data = props.data
      break
    case 'spacer-widget':
      widgetOptions.component = CmsWidgetSpacer
      widgetOptions.props.data = props.data
      break
    case 'subscribe-widget':
      widgetOptions.component = CmsWidgetSubscribeInline
      widgetOptions.props.data = props.data
      break
    case 'subscribe-form-widget':
      widgetOptions.component = CmsWidgetSubscribeForm
      widgetOptions.props.data = props.data
      break
    case 'svg-widget':
      widgetOptions.component = CmsWidgetSvgWidget
      widgetOptions.props.spaceLeft = props.spaceLeft
      widgetOptions.props.data = props.data
      break
    case 'table-widget':
      widgetOptions.component = LazyCmsWidgetTable
      widgetOptions.props.data = props.data
      break
    case 'tags-widget':
      widgetOptions.component = LazyCmsWidgetTags
      break
    case 'text-widget':
      widgetOptions.component = CmsWidgetText
      widgetOptions.props.data = props.data
      break
    case 'video-widget':
      widgetOptions.component = CmsWidgetVideo
      widgetOptions.props.data = props.data
      break
    case 'video-banner-widget':
      widgetOptions.component = CmsWidgetVideoBanner
      widgetOptions.props.data = props.data
      break
    case 'video-button-widget':
      widgetOptions.component = CmsWidgetVideoButton
      widgetOptions.props.data = props.data
      break
    case 'visibility-widget':
      widgetOptions.component = CmsWidgetVisibility
      widgetOptions.props.data = props.data
      break
    case 'yotpo-site-reviews-widget':
      widgetOptions.component = LazyCmsWidgetYotpoSiteReviews
      break
    case 'yotpo-social-images-widget':
      widgetOptions.component = LazyCmsWidgetYotpoSocialImages
      widgetOptions.props.data = props.data
      break
    default:
      addWidgetDefaultClasses = false
      widgetOptions.props.data = props.data
      break
  }

  if (addWidgetDefaultClasses) {
    const padding = generatePaddingClasses(
      props.data.mobilePadding,
      props.data.tabletPadding,
      props.data.desktopPadding
    )
    if (padding) widgetOptions.props.class.push(padding)
    if (props.data.backgroundColor) {
      const bgColor = generateBackgroundColorClass(props.data.backgroundColor)
      if (bgColor) widgetOptions.props.class.push(bgColor)
    }
  }

  return widgetOptions
})
</script>
