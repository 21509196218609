<template lang="pug">
nav(v-if='navbarData' aria-label='header-nav' class='relative' :class='mainCss')
  div(class='flex justify-between h-[52px] overflow-hidden')
    div(v-if='!$device.isSmall' data-testid='navigationBar' class='flex flex-1 flex-wrap gap-x-4')
      template(v-for='item in navbarData.items')
        template(v-if='$storyblok.isVisible(item)')
          CmsWidgetAbTest(v-if='item.component === "ab-test-widget"' :key='item._uid' :data='item')
          HeaderNavbarDesktopNavItem(v-else :key='item._uid' :data='item')
            template(#default='{ isMenuOpen }')
              UniversalLink(
                v-if='item.component === "text-menu-item"'
                data-testid='navigationBarLink-text-menu-item'
                :link='item.link.url'
                class='px-3 h-[52px] font-bold flex items-center whitespace-nowrap hover:no-underline text-sm'
                :class='[linkClasses.hover, isMenuOpen ? linkClasses.open : linkClasses.closed]'
              ) {{ item.text }}
              div(
                v-else
                :data-testid='`navigationBarLink-${item.component}`'
                class='px-3 h-[52px] font-bold flex items-center cursor-default whitespace-nowrap hover:no-underline text-sm'
                :class='[linkClasses.hover, isMenuOpen ? linkClasses.open : linkClasses.closed]'
              ) {{ item.text }}

    FitmentButton(:icon='navbarData.fitmentImage' class='flex-1 lg:flex-none' :class='fitmentCss')
</template>

<script setup lang="ts">
const { $sitewideConfig, $device, $storyblok } = useNuxtApp()
const previewData = ref()

if ($storyblok.isPreview.value) {
  const { data } = await useAsyncData('navbar-data', async () => {
    if ($storyblok.contentType.value !== 'navbar') return null
    const preview = await $storyblok.getPreviewStory<NavbarStory>()
    return preview?.story.content ?? null
  })

  if (data.value) previewData.value = data.value
}

const navbarData = computed(() => {
  return previewData.value || $sitewideConfig.navbar.value?.content
})

onMounted(() => {
  // Add a listener to update the content when in editor mode
  $storyblok.addListener((event) => {
    if ($storyblok.isNavbarStory(event.story)) previewData.value = event.story.content
  })
})

const fitmentCss = computed(() => {
  return [
    generateBackgroundColorClass(navbarData.value.fitmentBackgroundColor),
    generateFontColorClass(navbarData.value.fitmentTextColor),
  ]
})

const mainCss = computed(() => {
  const css = [generateBackgroundColorClass(navbarData.value.desktopBackgroundColor)]

  if (navbarData.value.desktopBottomBorderColor && navbarData.value.desktopBottomBorderColor !== 'NONE') {
    css.push('border-b', generateBorderColor(navbarData.value.desktopBottomBorderColor))
  }

  // Determine Z-index
  if ($storyblok.isEditing.value && $storyblok.contentType.value === 'navbar') {
    // We want the navbar above the storyblock click blocker div
    css.push('z-[70]')
  } else {
    css.push('z-10')
  }

  return css
})

const linkClasses = computed(() => {
  const fontColor = generateFontColorClass(navbarData.value.desktopTextColor)
  const isActiveFontColor = generateFontColorClass(navbarData.value.desktopHoverTextColor)

  const classes = {
    hover: [`hover:${isActiveFontColor}`],
    open: [isActiveFontColor],
    closed: [fontColor],
  }

  // The classes changed when Open based on hover style
  let isActiveBorderColor: string
  let isActiveBgColor: string
  switch (navbarData.value.desktopHoverStyle) {
    case 'UNDERLINE':
      isActiveBorderColor = generateBorderColor(navbarData.value.desktopHoverColor)
      classes.closed.push('border-b-2', 'border-transparent')
      classes.open.push(`border-b-2 ${isActiveBorderColor}`)
      classes.hover.push(`hover:border-b-2 hover:${isActiveBorderColor}`)
      break
    case 'BACKGROUND':
      isActiveBgColor = generateBackgroundColorClass(navbarData.value.desktopHoverColor)
      classes.open.push(isActiveBgColor)
      classes.hover.push(`hover:${isActiveBgColor}`)
      break
  }

  return classes
})
</script>
