// This is what's left of a Nuxt 2 mixin that was used between fitment components.  This could probably be moved to the useFitmentDisplay store, I'm not sure it surves any real purpose as a stand-alone.
export default function () {
  const layoutPosition = ref('') // for segment
  const { $uiEvents, $sitewideConfig } = useNuxtApp()
  const displayStore = useFitmentDisplayStore()
  const fitmentStore = useFitmentStore()
  const route = useRoute()
  const routeBuilder = useRouteBuilder()
  const error = useError()

  const allDoneSelecting = computed(() => {
    const requiredSelectValidation = displayStore.modalRequiredSelectTypes.filter((select) => {
      return select.currentValue === '-1'
    })

    const optionalSelectValidation = displayStore.modalOptionalSelectTypes.filter((select) => {
      return select.hasError
    })

    return requiredSelectValidation.length === 0 && optionalSelectValidation.length === 0
  })

  function selectChanged({ sourceType, newValue }: { sourceType: FitmentTextKey; newValue: string }) {
    displayStore.setSelectValue(sourceType, newValue)
    displayStore.fillNextSelect(sourceType)

    $uiEvents.$emit('fitmentChanged', {
      sourceType,
      newValue,
      ...(layoutPosition.value && { layoutPosition: layoutPosition.value }),
    })
  }

  function selectChangedOptional({ sourceType, newValue }: { sourceType: FitmentTextKey; newValue: string }) {
    displayStore.setSelectValue(sourceType, newValue)
    displayStore.validateFitment(sourceType)

    $uiEvents.$emit('fitmentChanged', {
      sourceType,
      newValue,
      ...(layoutPosition.value && { layoutPosition: layoutPosition.value }),
    })
  }

  /**
   * @param {Boolean} ignoreISSD is used by fitmentInline to skip adding sameDayShipping to query
   */
  function saveFitment(ignoreISSD = false) {
    displayStore.saveFitment()

    let newRoute: any = {}
    // Send to the MMY page if you are: update the mmy page, on a 404 page, set the mode to sendToMMY
    if (route.name?.toString() === 'mmy' || error.value !== undefined || displayStore.modal.isMMYMode) {
      // linkBuilder isn't made for non fitment urls (v/blah/blah) as it doesn't really seem to make sense
      newRoute = {
        name: 'mmy',
        params: {
          makeSlug: fitmentStore.makeSlug,
          modelSlug: fitmentStore.modelSlug,
          year: fitmentStore.year,
        },
        query: {},
      }
    } else {
      const query: any = {}
      // If we have a "q" in the query obj it means we are on the search page. We need to pass that along when we update fitment.
      if (route.query.q) query.q = route.query.q

      // If we have a "sort" in the query obj it means we have a sort active. We need to pass that along when we update fitment.
      if (route.query.sort) query.sort = route.query.sort

      // Constructor uses "sortBy" and "sortOrder" instead of "sort"
      if (route.query.sortBy) query.sortBy = route.query.sortBy
      if (route.query.sortOrder) query.sortOrder = route.query.sortOrder

      // If we have a "token" in the query obj it means we have an build edit. We need to pass that along when we update fitment.
      if (route.query.token) query.token = route.query.token

      newRoute = routeBuilder.formatRoute({
        name: route.name?.toString(),
        params: route.params,
        query,
      })
    }

    // If we are in the sameDayShipping mode then we need to add this query string so the facet will display on the cat page.
    if (displayStore.modal.isSameDayShippingMode && !ignoreISSD) newRoute.query.sameDayShipping = true

    let forceRefresh = false
    const isSameRoute =
      route.params.makeSlug == newRoute.params.makeSlug &&
      route.params.modelSlug == newRoute.params.modelSlug &&
      route.params.year == newRoute.params.year

    // This assumes that the pline page will refresh when you add fitment to the url, it doesn't.  We have to add Z no matter what for that :(
    // TODOLATERER: In the future, I wonder if this shouldn't just emit a uiEvent saying fitment changed and it's up to the current page to listen and do what it needs.
    if (route.name?.toString().startsWith('product') || route.name?.toString() === 'sku') forceRefresh = true
    // Did you only update a BBE?  Add a dumb query param to force the route change since we only keep mmy in the url
    // This used to be a string compare between fullPaths, but router.resolve would return paths without slashes?!
    if (isSameRoute || forceRefresh) newRoute.query.z = new Date().getTime()

    navigateTo(newRoute)
  }

  return {
    layoutPosition,
    allDoneSelecting,
    selectChanged,
    selectChangedOptional,
    saveFitment,
  }
}
