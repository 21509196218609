export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled, isProduction } = useUtils()
  if (!isIntegrationEnabled('affirm')) return

  const { $sitewideConfig } = useNuxtApp()

  const scriptSrc = isProduction()
    ? 'https://cdn1.affirm.com/js/v2/affirm.js'
    : 'https://cdn1-sandbox.affirm.com/js/v2/affirm.js'

  const _affirm_config = {
    public_api_key: $sitewideConfig.config.affirmApiKey,
    script: scriptSrc,
  }

  const loader = runOnDemand()

  loader.addCallback(() => {
    /* eslint-disable */
    // prettier-ignore
    // @ts-ignore
    // (function(l,g,m,e,a,f,b){var d,c=l[m]||{},h=document.createElement(f),n=document.getElementsByTagName(f)[0],k=function(a,b,c){return function(){a[b]._.push([c,arguments])}};c[e]=k(c,e,"set");d=c[e];c[a]={};c[a]._=[];d._=[];c[a][b]=k(c,a,b);a=0;for(b="set add save post open empty reset on off trigger ready setProduct".split(" ");a<b.length;a++)d[b[a]]=k(c,e,b[a]);a=0;for(b=["get","token","url","items"];a<b.length;a++)d[b[a]]=function(){};h.async=!0;h.src=g[f];n.parentNode.insertBefore(h,n);delete g[f];d(g);l[m]=c})(window,affirmConfig,"affirm","checkout","ui","script","ready");
    (function(m,g,n,d,a,e,h,c){var b=m[n]||{},k=document.createElement(e),p=document.getElementsByTagName(e)[0],l=function(a,b,c){return function(){a[b]._.push([c,arguments])}};b[d]=l(b,d,"set");var f=b[d];b[a]={};b[a]._=[];f._=[];b._=[];b[a][h]=l(b,a,h);b[c]=function(){b._.push([h,arguments])};a=0;for(c="set add save post open empty reset on off trigger ready setProduct".split(" ");a<c.length;a++)f[c[a]]=l(b,d,c[a]);a=0;for(c=["get","token","url","items"];a<c.length;a++)f[c[a]]=function(){};k.async=!0;k.src=g[e];p.parentNode.insertBefore(k,p);delete g[e];f(g);m[n]=b})(window,_affirm_config,"affirm","checkout","ui","script","ready","jsReady");
    /* eslint-enable */
  })

  // Refresh affirm ui widgets
  function refresh() {
    window.affirm.ui.ready(() => {
      window.affirm.ui.refresh()
    })
  }

  return {
    provide: {
      affirm: {
        load: loader.load,
        refresh,
      },
    },
  }
})
