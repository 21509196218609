<!-- eslint-disable vue/no-v-html -->
<template lang="pug">
div
  //- .select is a global style defined in forms.styl
  div(class='select capitalize')
    select(
      v-model='selectedValue'
      data-testid='fitmentSelect'
      :disabled='select.isDisabled'
      :class='selectClass'
      :aria-label='selectOptions[0].text'
    )
      option(
        v-for='(option, index) in selectOptions'
        :key='index'
        :value='option.value'
        :disabled='option.isDisabled'
        v-html='option.text'
      )
  div(v-if='select.hasError' class='text-danger') This {{ select.type }} is not valid for your current vehicle.
</template>

<script setup lang="ts">
const emit = defineEmits(['changed'])

const {
  select,
  currentValue = '',
  disableHighlight = false,
} = defineProps<{
  select: FitmentSelect
  currentValue: string | number
  disableHighlight?: boolean
}>()

const selectOptions = computed(() => {
  return select.options.filter((option) => !option.isHidden)
})

const selectedValue = computed({
  get: () => currentValue,
  set: (value) => {
    emit('changed', {
      sourceType: select.type,
      newValue: value,
    })
  },
})

const selectClass = computed(() => {
  // -needsSelection and -hasSelection are global css states and used by alert.js
  return {
    '-needsSelection': !disableHighlight && currentValue === '-1' && !select.isDisabled,
    '-hasSelection': currentValue !== '-1' && !select.isDisabled,
    '!text-danger !border-danger': select.hasError,
  }
})
</script>
