import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 24 21"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M9.9 15.7c-1.4 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6 2.6-1.2 2.6-2.6-1.2-2.6-2.6-2.6m0 3.5c-.6 0-1-.5-1-1s.4-1 1-1 1 .5 1 1-.5 1-1 1m7.8-3.5c-1.4 0-2.6 1.2-2.6 2.6s1.2 2.6 2.6 2.6 2.6-1.2 2.6-2.6-1.1-2.6-2.6-2.6m0 3.5c-.6 0-1-.5-1-1s.5-1 1-1c.6 0 1 .5 1 1 0 .6-.5 1-1 1m5.7-16.6c-.4-.1-.9.2-1 .6L21 9.4l-11.9 1-2.5-6.2L5.2.5C5 .2 4.7 0 4.4 0H.8C.4 0 0 .4 0 .8s.4.8.8.8h3.1l3.9 9.6L6.6 14c-.1.2-.1.5.1.7.1.3.3.4.6.4h13c.4 0 .8-.4.8-.8s-.4-.8-.8-.8H8.5l.7-1.5 12.6-1c.3 0 .6-.3.7-.6L24 3.6c.1-.4-.2-.9-.6-1" }, null, -1)
  ])))
}
export default { render: render }