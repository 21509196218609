export default function () {
  const { $cognito, $sitewideConfig } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()
  const { getApiUrl } = useUrls()
  const notifications = useNotifications()
  const cart = useCart()

  const url = getApiUrl('cart')

  const wishlist = useState('wishlist')

  async function fetchWishlist() {
    const wishlistId = await getWishlistId()

    if (!wishlistId) {
      wishlist.value = null
      return
    }

    try {
      wishlist.value = await $fetch(`${url}${wishlistId}`, {
        method: 'GET',
        headers: { 'x-site': $sitewideConfig.sitePrefix },
      })
    } catch (error) {
      notifications.addError('Error', 'Could not get list. Please try again.')
    }
  }

  async function addToWishlist(item: CartItem) {
    const wishlistId = await getWishlistId()
    // Throw an error here instead of returning so the user gets an error message
    if (!wishlistId) throw new Error('No wishlist id')

    const { productId, qty, fitmentData, meta } = item

    await $fetch(`${url}${wishlistId}/items`, {
      method: 'POST',
      headers: { 'x-site': $sitewideConfig.sitePrefix },
      body: {
        productList: {
          productId,
          qty,
          fitmentData,
          ...(meta && { meta }),
        },
      },
    })

    await fetchWishlist()
  }

  async function removeFromWishlist(itemId: string) {
    const wishlistId = await getWishlistId()
    // Throw an error here instead of returning so the user gets an error message
    if (!wishlistId) throw new Error('No wishlist id')

    await $fetch(`${url}${wishlistId}/items/${itemId}`, {
      method: 'DELETE',
      headers: {
        'x-site': $sitewideConfig.sitePrefix,
      },
    })

    // Get a fresh copy of the wishlist because the remove response does not update itemIds
    await fetchWishlist()
  }

  async function moveToWishlist(item: CartItem) {
    // Remove item from cart, if this fails it will be caught higher up
    // and we do not have to do anything
    await cart.removeItem(item.id)

    // Add item to wishlist, if it fails then we need to add the item back to the cart
    try {
      await addToWishlist(item)
    } catch (error: any) {
      await cart.addItems([item])
      throw error
    }
  }

  async function moveToCart(item: CartItem) {
    // Remove item from wishlist, if this fails it will be caught higher up
    // and we do not have to do anything
    await removeFromWishlist(item.id)

    // Add item to cart, if it fails then we need to add it back to the wishlist
    try {
      await cart.addItems([item])
    } catch (error: any) {
      await addToWishlist(item)
      throw error
    }
  }

  async function getWishlistId() {
    if (!isIntegrationEnabled('cognito')) return

    const userAttributes = await $cognito.getUserAttributes()
    const userId = userAttributes?.id

    if (!userId) return

    return `${userId}-wishlist`
  }

  return {
    data: readonly(wishlist),
    init: fetchWishlist,
    getWishlistId,
    moveToWishlist,
    moveToCart,
    removeFromWishlist,
  }
}
