<template lang="pug">
div(:key='carouselKey' :class='{ "max-w-[1920px] mx-auto w-full": data.type === "BANNER" }')
  Carousel(v-bind='carouselOptions')
    template(v-for='(item, index) in data.items' :key='item._uid')
      CmsWidgetImageCarouselItem(
        :data='item'
        :label-position='data.labelPosition'
        :text-color='data.textColor'
        :text-position='data.textPosition'
        :shadow-opacity='data.shadowOpacity'
        :shadow-top-position='data.shadowTopPosition'
        :shadow-bottom-position='data.shadowBottomPosition'
        :class='[data.type === "BANNER" ? "w-full min-w-full" : "flex-none"]'
        :style='carouselItemStyle'
      )
        template(#default='{ widget }')
          slot(
            :data='widget'
            :space-left='carouselSpacePerItem'
            :force-lazy-load='data.type === "BANNER" && index > 0'
            :is-important='data.type === "BANNER" && index === 0'
          )
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()
interface ImageCarousel extends Widget {
  component: 'image-carousel-widget'
  type: 'BANNER' | 'CARD' | 'CUSTOM'
  items: any
  labelPosition: 'INSET' | 'BOTTOM'
  textColor: FontColor
  textPosition: VerticalAlignment | 'OUTSIDE'
  shadowOpacity: string
  mobileCustomSize: string
  tabletCustomSize: string
  desktopCustomSize: string
  shadowTopPosition: string
  shadowBottomPosition: string
}
const props = defineProps<{
  data: ImageCarousel
}>()

const carouselKey = computed(() => {
  return `${props.data._uid}-${props.data.items.length}`
})

const carouselSpacePerItem = computed(() => {
  const spaceLeftValue = 256
  if (props.data.type === 'CARD') {
    return {
      mobile: spaceLeftValue,
      tablet: spaceLeftValue,
      desktop: spaceLeftValue,
    }
  } else if (props.data.type === 'CUSTOM') {
    const customSpaceLeft = {
      mobile: spaceLeftValue,
      tablet: spaceLeftValue,
      desktop: spaceLeftValue,
    }
    const mobileCustomValue = parseInt(props.data.mobileCustomSize)
    const tabletCustomValue = parseInt(props.data.tabletCustomSize)
    const desktopCustomValue = parseInt(props.data.desktopCustomSize)

    if (mobileCustomValue) {
      customSpaceLeft.mobile = mobileCustomValue
      customSpaceLeft.tablet = mobileCustomValue
      customSpaceLeft.desktop = mobileCustomValue
    }

    if (tabletCustomValue) {
      customSpaceLeft.tablet = tabletCustomValue
      customSpaceLeft.desktop = tabletCustomValue
    }

    if (desktopCustomValue) {
      customSpaceLeft.desktop = desktopCustomValue
    }

    return customSpaceLeft
  }
  return null
})

const carouselItemStyle = computed(() => {
  if (props.data.type !== 'CUSTOM' || !carouselSpacePerItem.value) return {}
  let width
  // Choose maxWidth value based upon device
  if ($device.value.isMobile) width = carouselSpacePerItem.value.mobile
  else if ($device.value.isDesktop) width = carouselSpacePerItem.value.desktop
  else width = carouselSpacePerItem.value.tablet

  return { width: `${width}px` }
})

const carouselOptions = computed(() => {
  const options: {
    arrowMode?: 'HOVER' | 'ALWAYS'
    isLooped?: boolean
    containerClass?: string
  } = {}

  switch (props.data.type) {
    case 'BANNER':
      options.arrowMode = $device.value.isDesktop ? 'HOVER' : 'ALWAYS'
      options.isLooped = true
      break

    default:
      options.containerClass = 'gap-x-4'
      break
  }

  return options
})
</script>
