// For fitment display logic (filling selects, shuffling required/optional, etc..)
export const useFitmentDisplayStore = defineStore('FitmentDisplay', () => {
  const { $sitewideConfig, $algolia, $overlay } = useNuxtApp()
  const fitmentStore = useFitmentStore()
  const hasRequiredFitmentChanged = ref(false)
  const defaultSelectTypes: Ref<FitmentTextKey[]> = ref([])
  const requiredSelectTypes: Ref<FitmentTextKey[]> = ref([])
  const optionalSelectTypes: Ref<FitmentTextKey[]> = ref([])
  const didNotSave = ref(false) // these were weird in Nuxt 2, outside exported functions
  const autoSelectEnabled = ref(false) // these were weird in Nuxt 2, outside exported functions
  const modal = ref({
    selects: [] as FitmentSelect[],
    isFitsMode: false,
    isSameDayShippingMode: false,
    isMMYMode: false,
  })

  const hasFullFitment = computed(() => {
    return (
      requiredSelectTypes.value.filter((selectType) => {
        const key: FitmentSlugKey = selectType != 'year' ? `${selectType}Slug` : selectType
        return !fitmentStore.$state[key]
      }).length == 0
    )
  })

  const hasNoFitment = computed(() => {
    return (
      requiredSelectTypes.value.filter((selectType) => {
        const key: FitmentSlugKey = selectType != 'year' ? `${selectType}Slug` : selectType
        return fitmentStore.$state[key]
      }).length == 0
    )
  })

  const hasYmmFullFitment = computed(() => {
    return (
      requiredSelectTypes.value.filter((selectType) => {
        const key: FitmentSlugKey = selectType != 'year' ? `${selectType}Slug` : selectType
        return fitmentStore.$state[key]
      }).length >= 3
    )
  })

  const hasPartialFitment = computed(() => {
    return !hasYmmFullFitment.value && !hasNoFitment.value
  })

  const modalRequiredSelectTypes = computed(() => {
    return modal.value.selects.filter((select) => requiredSelectTypes.value.includes(select.type))
  })

  const modalOptionalSelectTypes = computed(() => {
    return modal.value.selects.filter((select) => optionalSelectTypes.value.includes(select.type))
  })

  const selectTypes = computed(() => {
    return [...requiredSelectTypes.value, ...optionalSelectTypes.value]
  })

  function setupFitment(extraData: any = {}) {
    const newTypes = {
      required: ['year', 'make', 'model'] as FitmentTextKey[],
      optional: [] as FitmentTextKey[],
    }

    // Determine if this BBE are Required or Optional
    const bbeTypes: FitmentTextKey[] = ['body', 'bed', 'engine']

    bbeTypes.forEach((type) => {
      const fitmentKeyTitleCase = `fitment${
        type.charAt(0).toUpperCase() + type.slice(1)
      }` as keyof typeof $sitewideConfig.config
      const runtimeValue = $sitewideConfig.config[fitmentKeyTitleCase]
      const customFitmentValue = extraData[type]
      const currentFitmentValue = fitmentStore.$state[type] //state.data[type]

      if (runtimeValue || customFitmentValue || currentFitmentValue) {
        // If its not required by default but is by apiData: set it to required
        if (currentFitmentValue || (runtimeValue !== 'REQUIRED' && customFitmentValue)) {
          newTypes.required.push(type)
        } else if (runtimeValue === 'REQUIRED') {
          newTypes.required.push(type)
        } else if (runtimeValue === 'OPTIONAL') {
          newTypes.optional.push(type)
        }
        // Otherwise obey the default (will set it to required if runtime and apiData agree or set it to optional)
      }
    })
    // Only commit if something changed
    if (requiredSelectTypes.value.length === newTypes.required.length) {
      // If the keys are exactly the same, early out
      if (requiredSelectTypes.value.every((selectType) => newTypes.required.includes(selectType))) {
        hasRequiredFitmentChanged.value = false
        return
      }
    }

    requiredSelectTypes.value = newTypes.required
    optionalSelectTypes.value = newTypes.optional
    hasRequiredFitmentChanged.value = true

    // This is required to update the Order of the selects
    setupEmptySelects()
  }

  function setupEmptySelects() {
    modal.value.selects = getEmptySelects(selectTypes.value)
  }

  function initSelects() {
    fillNextSelect()

    // in the case where the screen rendered with an existing ymm, and they selected a ymm from a product line page, make sure to fill them up!
    autoSelectEnabled.value = true
  }

  async function fillNextSelect(sourceType?: FitmentTextKey) {
    const destIndex = sourceType ? selectTypes.value.indexOf(sourceType) + 1 : 0
    let doneSelectingRequired = false

    // if we don't have any optionalSelectTypes and we are selecting the last requiredSelectType then we need to disable the auto selecting feature.
    if (optionalSelectTypes.value.length === 0 && sourceType === requiredSelectTypes.value.slice(-1)[0])
      autoSelectEnabled.value = false

    // if we are done selecting the requiredSelectTypes then we need to know to be done with them so we trigger the correct things when we get algolia results.
    if (destIndex === requiredSelectTypes.value.length) doneSelectingRequired = true
    $algolia.searchContexts.fitment.clearRefinements()

    requiredSelectTypes.value.forEach((select, index) => {
      // add the facet to the query if we have all of the required values or add filters for everything before destIndex
      if (doneSelectingRequired || index < destIndex) {
        const selectData = modal.value.selects[selectTypes.value.indexOf(select)]
        if (selectData.currentValue !== '-1')
          $algolia.searchContexts.fitment.addRefinements(getTypeSlug(selectData.type), selectData.currentValue)
      }
    })

    // Search for results
    await $algolia.searchContexts.fitment.search()

    if (!doneSelectingRequired) {
      const destType = selectTypes.value[destIndex]
      const options = [
        getEmptyOption(destType, true),
        ...getSelectOptions(destType, $algolia.searchContexts.fitment.state.value.refinements),
      ]
      setSelectOptions(destIndex, options)

      selectTypes.value.forEach((selectType, index) => {
        if (index > destIndex) {
          const select = getSelect(selectType)
          if (select) {
            select.isDisabled = true
            select.currentValue = '-1'
            select.hasError = false
          }
        }
      })

      const foundOption = findOptionByValue(options, fitmentStore.$state[getTypeSlug(destType)])
      // auto select their full or partial ymm, but only the first time through
      if (foundOption !== undefined && autoSelectEnabled.value) {
        setSelectValue(destType, foundOption.value)
        fillNextSelect(destType)
      }
    } else {
      optionalSelectTypes.value.forEach((select) => {
        const options = [
          getEmptyOption(select, true),
          ...getSelectOptions(select, $algolia.searchContexts.fitment.state.value.refinements),
        ]
        setSelectOptions(selectTypes.value.indexOf(select), options)

        const foundOption = findOptionByValue(options, fitmentStore.$state[getTypeSlug(select)])
        // auto select their full or partial ymm, but only the first time through
        if (foundOption && autoSelectEnabled) {
          setSelectValue(select, foundOption.value)
        }
      })

      // if we hit this point then we have already auto selected once, meaning that we don't want to auto populate anything anymore.
      autoSelectEnabled.value = false
    }
  }

  function setSelectValue(sourceType: FitmentTextKey, newValue: string) {
    const index = selectTypes.value.indexOf(sourceType)
    modal.value.selects[index].currentValue = newValue
  }

  function setSelectOptions(index, options) {
    const select = modal.value.selects[index]
    select.options = options
    select.isDisabled = false
    select.currentValue = '-1'
    select.hasError = false

    // If we are setting select options for make then sort it by top makes
    if (select.type === 'make') sortFeaturedMakes(select.options, $sitewideConfig.config.featuredMakes?.data)

    // if we only have 2 options and the second one is 'n-a' then we want to change the label
    if (options.length === 2 && options[1].value === 'n-a') {
      // Capitalize the Type
      options[1].text = `No ${select.type.charAt(0).toUpperCase() + select.type.slice(1)}`
    } else {
      // We do not want to show n-a when the select has at least 1 valid fitment option
      options.forEach((option) => {
        if (option.value === 'n-a') option.isHidden = true
      })
    }
  }

  async function validateFitment(sourceType: FitmentTextKey) {
    $algolia.searchContexts.fitment.clearRefinements()

    // add filters for everything that we have values for.
    modal.value.selects.forEach((select) => {
      if (select.currentValue !== '-1')
        $algolia.searchContexts.fitment.addRefinements(getTypeSlug(select.type), select.currentValue)
    })

    await $algolia.searchContexts.fitment.search()

    // The nuxt 2 code just reset all errors, but this assumed you weren't dumb enough to have 2 errors for 2 different drills
    // I'm not sure which would be worse, pretending your other error is ok, or to keep showing the error even if it's no longer a problem.
    // Honestly, we should probably revalidate fitment for all optional drills here.
    const select = getSelect(sourceType)
    if (select) select.hasError = $algolia.searchContexts.fitment.state.value.resultCount === 0
    // reset all like in Nuxt 2 - modal.value.selects.forEach((select) => (select.hasError = false))
  }

  // notice we start with a new obj, not try to merge selections
  // If you pass it fitmentData, then it will save that instead -- 1/12/24 -- Josh says this is weird..
  // this is used to overwrite the fitment data when editing a build
  function saveFitment(fitmentData?: any) {
    const obj: Fitment = {
      year: '',
      make: '',
      makeSlug: '',
      model: '',
      modelSlug: '',
    }

    selectTypes.value.forEach((type, index) => {
      const selectedOption = findOptionByValue(
        modal.value.selects[index].options,
        modal.value.selects[index].currentValue
      )

      if (selectedOption.value !== '-1') {
        obj[type] = selectedOption.text
        if (type !== 'year') obj[`${type}Slug`] = selectedOption.value
      }
    })

    fitmentStore.update(fitmentData || obj)
    didNotSave.value = false
  }

  function resetFitmentModal() {
    modal.value.isFitsMode = false

    if (didNotSave.value) {
      autoSelectEnabled.value = true
      initSelects()
    }
  }

  function showFitmentModal(options: any = {}) {
    const { isFitsMode = false, isSameDayShippingMode = false, isMMYMode = false } = options
    modal.value.isFitsMode = isFitsMode
    modal.value.isSameDayShippingMode = isSameDayShippingMode
    modal.value.isMMYMode = isMMYMode
    didNotSave.value = true
    $overlay.open('FitmentModal')
  }

  function clearFitment() {
    fitmentStore.clear()

    if (!import.meta.client) {
      // since fitment is reset in middleware, but init in a plugin we have to resetup empty selects
      setupEmptySelects()
      return
    }
    setupFitment()
    initSelects()
  }

  function getSelect(sourceType: FitmentTextKey) {
    return modal.value.selects.find((select) => select.type == sourceType)
  }

  // takes a select obj and returns the selected object
  function findOptionByValue(options: any[], value: string) {
    return options.filter((obj) => {
      return obj.value === value
    })[0]
  }

  function getSelectOptions(destType: FitmentTextKey, facets) {
    const textFacets = facets[destType]
    const slugFacets = facets[`${destType}Slug`] || facets[destType]

    if (!textFacets) return []

    const options = textFacets.map((facet, index) =>
      getOptionObject(facet.value, slugFacets[index].value, false, false)
    )

    return destType !== 'year' ? options : options.reverse()
  }

  function getTypeSlug(type: FitmentTextKey): FitmentSlugKey {
    return type === 'year' ? type : `${type}Slug`
  }

  function getOptionObject(text: string, value: string, isDisabled: boolean, isHidden: boolean) {
    return {
      text,
      value,
      isDisabled,
      isHidden,
    }
  }

  function getEmptySelects(selectTypes: FitmentTextKey[]) {
    return selectTypes.map((type) => {
      return getEmptySelect(type)
    })
  }

  function getEmptySelect(type: FitmentTextKey) {
    const options = []
    options.push(getEmptyOption(type))

    return {
      type,
      currentValue: '-1',
      isDisabled: type !== 'year',
      options,
      hasError: false,
    }
  }

  // If you have a partial MMY then the first option will be "Chevy" instead of "Select Make" etc..  useRealDefault will always show Select ______
  function getEmptyOption(type: FitmentTextKey, useRealDefault = false) {
    const fitmentText = fitmentStore.$state[type] || ''
    const label = fitmentText && !useRealDefault ? fitmentText : `Select ${type}`

    return getOptionObject(label, '-1', true, false)
  }

  return {
    hasRequiredFitmentChanged,
    defaultSelectTypes,
    requiredSelectTypes,
    optionalSelectTypes,
    modal,
    getSelect,
    hasFullFitment,
    hasNoFitment,
    hasYmmFullFitment,
    hasPartialFitment,
    modalRequiredSelectTypes,
    modalOptionalSelectTypes,
    selectTypes,
    setupFitment,
    initSelects,
    setSelectValue,
    fillNextSelect,
    validateFitment,
    saveFitment,
    resetFitmentModal,
    showFitmentModal,
    clearFitment,
  }
})
