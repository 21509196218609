<template lang="pug">
div(
  class='max-w-sm w-full shadow-lg rounded pointer-events-auto overflow-hidden border'
  :class='backgroundColorClass'
  @mouseenter='clearTimer'
  @mouseleave='resetTimer'
)
  div(class='p-4')
    div(class='flex items-start')
      div(class='shrink-0')
        SuccessIcon(v-if='props.notification.type === "SUCCESS"' class='h-6 w-6 fill-current text-success-dark')
        ErrorIcon(v-else-if='props.notification.type === "ERROR"' class='h-8 w-8 fill-current text-danger-dark')
        InfoIcon(v-else class='h-4 w-4 fill-current text-info')

      div(class='ml-3 w-0 flex-1')
        p(class='text-sm font-bold text-gray-darkest')
          //- Updated to use inline html because some product titles have html encoded characters
          //- when adding them to wishlist the text is not displayed properly
          InlineHtml(:text='props.notification.title')
        p(v-if='props.notification.message' class='mt-1 text-sm text-gray-darker')
          InlineHtml(:text='props.notification.message')

      div(class='ml-4 shrink-0 flex')
        button(
          type='button'
          class='rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none'
          @click='close'
        )
          span(class='sr-only') Close
          CloseX(aria-label='close' width='12' height='12' class='cursor-pointer w-3 h-3' loading='lazy' @click='close')
</template>

<script setup lang="ts">
import InfoIcon from '@/assets/info.svg?component'
import SuccessIcon from '@/assets/checkmarkCircle.svg?component'
import ErrorIcon from '@/assets/errorIcon.svg?component'
import CloseX from '@/assets/x.svg?component'

import type { Notification } from '@/composables/useNotifications'

const emit = defineEmits(['close'])
let timeout: any = null

const props = defineProps({
  notification: {
    type: Object as PropType<Notification>,
    required: true,
  },
  duration: {
    type: Number,
    default: 10000,
  },
})

const backgroundColorClass = computed(() => {
  if (props.notification.type === 'SUCCESS') return 'bg-gray-lighter'
  if (props.notification.type === 'ERROR') return 'bg-danger-light'
  return 'bg-white'
})

onMounted(() => {
  resetTimer()
})

function resetTimer() {
  timeout = setTimeout(() => {
    close()
  }, props.duration)
}

function clearTimer() {
  if (timeout) clearTimeout(timeout)
}

function close() {
  clearTimer()
  emit('close')
}
</script>
