const lightboxIndex = ref(0)
const lightboxItems: Ref<PhotoswipeItem[]> = ref([])
const lightboxShow = ref(false)

export default function () {
  const route = useRoute()
  const { $device } = useNuxtApp()

  /**
   * Shows the lightbox
   * @param items The image objects to display
   * @param index The image to start on
   */
  function showLightbox(items: PhotoswipeItem[], index: number) {
    lightboxIndex.value = index
    lightboxItems.value = items
    lightboxShow.value = true

    if (!$device.value.isMobile) return

    // remove query string param if they closed manually
    const unWatchClose = watch(lightboxShow, (newVal) => {
      if (newVal === true) return

      const query = Object.assign({}, route.query)
      delete query.mod
      navigateTo({ query })
      unWatchClose()
      unWatchRoute()
    })

    // auto close if they hit back
    const unWatchRoute = watch(
      () => route.query,
      (newVal, oldVal) => {
        if (oldVal.mod === 'imageList' && newVal.mod !== 'imageList') {
          lightboxShow.value = false
          unWatchRoute()
          unWatchClose()
        }
      }
    )

    const query = Object.assign({}, route.query)
    query.mod = 'imageList'
    navigateTo({ query })
  }

  return { showLightbox, appVars: { lightboxIndex, lightboxItems, lightboxShow } }
}
