<script lang="ts">
// https://github.com/vuejs/core/issues/6435
// Has issues using v-html with nuxt-link :(
// https://realtruck.com/p/carr-180-176-light-mount/ (Degree)
// https://realtruck.com/p/avs-aeroskin-lightshield/ (R)
import { h } from 'vue'
import type { PropType } from 'vue'
type Elements = 'span' | 'div'

export default {
  props: {
    wrapper: {
      required: false,
      type: String as PropType<Elements>,
      default: 'span',
    },
    text: {
      required: true,
      type: String,
    },
  },
  setup(props) {
    return () =>
      h(props.wrapper, {
        innerHTML: props.text,
      })
  },
}
</script>
