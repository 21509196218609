export default function () {
  let isReady = false

  if (import.meta.client) {
    callOnce('wistia-global', () => {
      window._wq = window._wq || []

      const { load, addSrc } = runScriptOnDemand()
      addSrc('https://fast.wistia.com/assets/external/E-v1.js')
      load()
    })
  }

  function loadVideo(id: string, disablePause = false) {
    if (!import.meta.client) return

    let video: any = null
    const { load, addSrc } = runScriptOnDemand()
    addSrc(`https://fast.wistia.com/embed/medias/${id}.jsonp`)

    const { observe } = useRunOnVisible()
    const el = document.getElementsByClassName(`wistia_async_${id}`)
    if (!el || el.length == 0) return

    observe(
      el[0],
      () => {
        if (isReady) return
        load()
        window._wq.push({
          id,
          onReady(v: any) {
            isReady = true
            video = v
          },
        })
      },
      () => {
        if (disablePause || !isReady) return
        video.pause()
      },
      false
    )
  }

  return { loadVideo }
}
