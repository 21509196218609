export default defineNuxtPlugin(() => {
  const { addSrc, load } = runScriptOnDemand()

  addSrc(`https://www.youtube.com/iframe_api`)

  return {
    provide: {
      youtube: {
        load,
      },
    },
  }
})
