import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  width: "150",
  height: "128.6",
  viewBox: "28 28 150 128.6"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M167.5 54.7c6.7 8.2 10 17.2 10 27 0 9.6-3.3 18.6-10 26.8a67.8 67.8 0 0 1-27.3 19.5 97.3 97.3 0 0 1-49.8 6.5 91.5 91.5 0 0 1-48 22.1c-1 .1-1.9-.1-2.6-.7a4.2 4.2 0 0 1-1.5-2.5c-.2-.3-.2-.6 0-1 .1-.5.2-.8.1-.9l.4-.8.5-.7a11.4 11.4 0 0 1 1.3-1.5 265 265 0 0 1 5.4-6 44.3 44.3 0 0 0 7.6-12.6 53 53 0 0 0 2.2-6.4 61.4 61.4 0 0 1-20.7-18.4 39.9 39.9 0 0 1-1.6-44.3c4-6.6 9.2-12.3 16-17.1a81.5 81.5 0 0 1 23.9-11.4c9.2-2.9 19-4.3 29.1-4.3 13.6 0 26.2 2.4 37.7 7.2a67.9 67.9 0 0 1 27.3 19.5" }, null, -1)
  ])))
}
export default { render: render }