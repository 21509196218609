import type { RouteLocationNormalized, RouteRecordName } from '#vue-router'

export default function () {
  const { $cognito, $sitewideConfig, $geolocation, $emailTracker, $ketch } = useNuxtApp()
  const rootStore = useRootStore()
  const { getFitment } = useSegmentProductsHelper()
  const { isIntegrationEnabled } = useUtils()
  // Get Google Analytic Cookies
  const rawClientId = useCookie('_ga').value // Example: 'GA1.1.72907443.1711643512'
  const rawSessionId = useCookie('_ga_JYX1FNTCMX').value // Example: 'GS1.1.1713383968.15.1.1713384461.60.1.248713784'

  // === PAGE EVENT === //
  const emitPage = (to: RouteLocationNormalized, from?: RouteLocationNormalized) => {
    if (!window.analytics) return

    const geoData = getGeoLocationData()
    const referrer = from?.fullPath ? `https://${$sitewideConfig.domain}${from.fullPath}` : undefined
    const pathParts = to.path.split('/v/')
    const fitmentSlug = pathParts[1] ? `/v/${pathParts[1]}` : undefined
    // Make sure we have a trailing slash
    let shortPath = pathParts[0]
    if (!shortPath.endsWith('/')) shortPath += '/'

    window.analytics.page(getPageName($sitewideConfig.config?.siteName), {
      ...(to.name && { pageType: getPageType(to.name) }),
      isSalesRep: isSalesRep(),
      page_hostname: $sitewideConfig.domain,
      page_short_path: shortPath,
      page_fitment_slug: fitmentSlug,
      page_fitment_year: to.params.year,
      page_fitment_make: to.params.makeSlug,
      page_fitment_model: to.params.modelSlug,
      url: location.href,
      path: location.pathname,
      referrer,
      ...(geoData && geoData),
      ...getGoogleAnalyticsId(),
    })
  }

  const getPageType = (routeName: RouteRecordName) => {
    if (typeof routeName !== 'string') return

    if (routeName.includes('category')) return 'catPage'
    if (routeName.includes('brand')) return 'brandPage'
    if (routeName.includes('product')) return 'productLinePage'
    if (routeName.includes('specials')) return 'specialsPage'
    if (routeName === 'sku') return 'skuPage'
    if (routeName === 'mmy') return 'vehiclePage'
    if (routeName === 'slug') return 'cmsPage'
    if (routeName === 'account') return 'myAccountPage'
    if (routeName === 'home') return 'homePage'
    return 'other'
  }

  const getPageName = (siteName: string) => {
    const title = document.title
    const cleanTitle = title.replace('| ' + siteName, '')
    return cleanTitle.trim()
  }

  // === IDENTIFY === //
  const emitIdentify = async (data: Partial<IdentityData> = {}) => {
    if (!window.analytics) return

    let email = data?.email ?? undefined
    let userId = data?.userId ?? undefined
    // We do not want to fire an identify event without a UserId.
    if (!userId) userId = await getUserId()
    // If we STILL do not have a userId, jump ship
    // Note: this means any sight without Cognito enabled will never run Identify
    // Chris Wall is aware of this issue.
    if (!userId) return

    // If they didn't specify an email, fetch it
    if (!email) email = $emailTracker.getEmail()

    // Pull out the rest of the data
    const { name, phone, address } = data

    // Format the Addreess
    let street = (address && address.address1) || null
    if (address && street && address.address2) street += ' ' + address.address2
    const addressObj =
      address && address.zipcode
        ? {
            country: address.country || null,
            street,
            state: address.stateId || null,
            city: address.city || null,
            postalCode: address.zipcode || null,
          }
        : null

    // Layer In GeoLocationData
    const geoData = getGeoLocationData()

    // Leave out any data we don't have
    const identity = {
      isSalesRep: isSalesRep(),
      ...(email && { id: email, email }),
      ...(name && { name }),
      ...(phone && { phone }),
      ...(addressObj && { address: addressObj }),
      ...(rootStore.globalId && { global_id: rootStore.globalId }),
      ...(rootStore.permId && { cart_id: rootStore.permId }),
      ...(geoData && geoData),
      ...getGoogleAnalyticsId(),
    }

    window.analytics.identify(userId, identity)
  }

  // Called by fitmentChanged event
  // Adds Fitment to the user
  const emitIdentifyFitment = async () => {
    if (!window.analytics) return

    // We do not want to fire an identify event without a UserId.
    const userId = await getUserId()
    if (!userId) return

    const email = $emailTracker.getEmail()

    const fitment = getFitment()
    const geoData = getGeoLocationData()

    window.analytics.identify(userId, {
      year: fitment.year,
      make: fitment.make,
      model: fitment.model,
      email,
      isSalesRep: isSalesRep(),
      ...(rootStore.globalId && { global_id: rootStore.globalId }),
      ...(geoData && geoData),
      ...getGoogleAnalyticsId(),
    })
  }

  // === TRACK EVENT === //
  const track = async (name: string, data: Record<string, any>) => {
    if (!window.analytics) return

    const geoData = getGeoLocationData()

    const trackData: Record<string, any> = {
      ...data,
      isSalesRep: isSalesRep(),
      ...(geoData && geoData),
      ...getGoogleAnalyticsId(),
    }

    const email = $emailTracker.getEmail()
    if (email) trackData.email = email

    // Add the userId if it exists
    if (!trackData.userId) {
      const userId = await getUserId()
      if (userId) trackData.userId = userId
    }

    window.analytics.track(name, trackData)
  }

  // === COGNITO USER INFORMATION === //
  const getUserId = async () => {
    if (!isIntegrationEnabled('cognito')) return null
    const userAttributes = await $cognito.getUserAttributes()
    return userAttributes?.id
  }

  const generateUserId = async (email: string) => {
    if (!isIntegrationEnabled('cognito')) return
    return await $cognito.generateUserId(email)
  }

  const isSalesRep = () => {
    return !!(isIntegrationEnabled('cognito') && $cognito.isSalesAgent())
  }

  // === GEOLOCATION ==//
  const getGeoLocationData = () => {
    if (!isIntegrationEnabled('geolocation')) return null

    return {
      edgePostalCode: $geolocation.edgePostalCode,
      userPostalCode: $geolocation.userPostalCode,
    }
  }

  const getGoogleAnalyticsId = () => {
    // Example rawClientId: 'GA1.1.72907443.1711643512'
    // I need 72907443.1711643512
    let client_id = undefined
    if (rawClientId) {
      const idArray = rawClientId.split('.')
      client_id = `${idArray[2]}.${idArray[3]}`
    }

    // Example rawSessionId: 'GS1.1.1713383968.15.1.1713384461.60.1.248713784'
    // I need 1713384461
    const session_id = rawSessionId ? rawSessionId.split('.')[5] : undefined

    return {
      client_id,
      session_id,
    }
  }

  // Exposed Methods are used by the plugin Listeners
  return {
    track,
    emitPage,
    emitIdentify,
    emitIdentifyFitment,
    generateUserId,
    getUserId,
  }

  interface IdentityData {
    email: string
    userId: string
    name: string
    phone: string
    address: Contact
  }
}
