<!-- eslint-disable vue/no-v-html -->
<template lang="pug">
div(class='cursor-pointer rounded overflow-hidden bg-white relative lg:overflow-visible')
  UniversalLink(:link='`/p/${listing.productLineSlug}/`' class='no-underline hover:no-underline')
    div(@click='$emit("productClicked")')
      div(class='relative m-auto')
        div(class='bg-radial-gradient absolute w-full h-full')
        img(
          v-if='listing.image'
          :key='listing.image.key'
          :alt='listing.image.title || ""'
          class='w-full'
          :src='imgSrc'
          :loading='lazyLoading'
          width='341'
          height='256'
          style='max-width: initial'
        )
        img(v-else src='/images/coming-soon.jpg' class='w-full' style='max-width: initial')
        div(class='absolute top-0 right-0')
          div(class='flex space-x-1 h-[26px]')
            div(
              v-if='showPromo && listing.badgeText && !$sitewideConfig.config.nonTransactionalEnabled'
              class='or-product-listing-special-tag bg-danger-dark text-white text-xs font-bold uppercase text-center px-2 flex items-center'
            ) {{ listing.badgeText }}
            div(
              v-else-if='showPromo && listing.isPreOrder'
              class='or-product-listing-pre-order-tag bg-info text-white text-xs font-bold uppercase text-center px-2 flex items-center'
            ) PRE-ORDER
            div(
              v-else-if='showPromo && listing.isNew'
              class='or-product-listing-new-tag bg-gray text-white text-xs font-bold uppercase text-center px-2 flex items-center'
            ) NEW ITEM
            MadeByRealtruck(
              v-if='showBadge && listing.brand && $sitewideConfig.config.madeByRealTruckEnabled'
              :brand='listing.brand'
            )
      div(class='pt-3 leading-5 text-black font-bold lg:p-0 lg:mt-3 text-pretty' data-testid='productLineName')
        InlineHtml(:text='listing.productLineName')

      div(v-if='showReviews || showSameDayShipping' class='flex flex-wrap items-center relative')
        Stars(
          v-if='showReviews'
          :average-rating='listing.averageRating'
          :total-reviews='listing.totalReviews'
          class='mr-2 py-1'
        )
        template(
          v-if='showSameDayShipping && $sitewideConfig.config.sameDayShippingEnabled && listing.sameDayShipping'
        )
          Tooltip(
            mode='hover'
            text='This product may ship the same day, depending on your vehicle.'
            :hover-delay='750'
          )
            SameDayShippingLogo(class='py-1 w-24')
      div(v-if='showPrice && !$sitewideConfig.config.nonTransactionalEnabled')
        span(class='text-gray-darkest text-xs uppercase') From:&nbsp;&nbsp;
        span(
          :data-discount='listing.hasDiscount'
          class='text-gray-darkest text-lg font-bold mr-2 md:text-2xl'
          :class='[{ "or-product-listing-sale-price text-danger": listing.hasDiscount }]'
        ) {{ formatCents(listing.salePrice) }}
        span(v-if='listing.hasDiscount' class='line-through inline-block text-sm text-gray-dark') {{ formatCents(listing.originalPrice) }}
      p(v-if='showShopNow && !$sitewideConfig.config.nonTransactionalEnabled' class='pt-1 text-action font-bold') Shop Now
</template>

<script setup lang="ts">
const { $sitewideConfig, $router } = useNuxtApp()
const routeBuilder = useRouteBuilder()
const { getImageUrl } = useUrls()
defineEmits(['productClicked'])

const {
  listing,
  isLazy = true,
  isSchemaOn = true,
  showShopNow = false,
  showPrice = true,
  showReviews = true,
  showSameDayShipping = true,
  showPromo = true,
  showBadge = true,
} = defineProps<{
  listing: ProductListing
  isLazy?: boolean
  isSchemaOn?: boolean
  showShopNow?: boolean
  showPrice?: boolean
  showReviews?: boolean
  showSameDayShipping?: boolean
  showPromo?: boolean
  showBadge?: boolean
}>()

const isInTest = ref(false)

const schema = computed(() => {
  const schema = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    name: listing.productLineName,
    url: appendTrailingSlash(
      'https://' +
        $sitewideConfig.domain +
        $router.resolve(
          routeBuilder.formatRoute({
            name: 'product',
            params: { productLine: listing.productLineSlug },
          })
        ).href
    ),
    image: getImageUrl(listing.image.key, listing.image.filename),
    brand: {
      '@type': 'Brand',
      name: listing.brand,
    },
    aggregateRating: {},
    offers: {},
  }

  if (listing.totalReviews > 0) {
    const agg: AggregateRating = {}
    agg['@type'] = 'AggregateRating'
    agg.ratingValue = listing.averageRating
    agg.reviewCount = listing.totalReviews
    schema.aggregateRating = agg
  }

  if (!listing.hasMAPViolation) {
    const offer: Offer = {}
    offer['@type'] = 'Offer'
    offer.price = listing.salePrice / 100
    offer.priceCurrency = 'USD'
    schema.offers = offer
  }

  return JSON.stringify(schema)
})

if (isSchemaOn && listing) {
  useHead({
    script: [{ type: 'application/ld+json', innerHTML: schema, tagPosition: 'bodyClose' }],
  })
}

const imgSrc = computed(() => {
  return getImageUrl(listing.image.key, listing.image.filename, 340, 255, 70, 'fff')
})

const lazyLoading = computed(() => {
  return isLazy ? 'lazy' : 'eager'
})

interface AggregateRating {
  '@type'?: string
  ratingValue?: any
  reviewCount?: any
  aggregateRating?: any
}

interface Offer {
  '@type'?: string
  price?: any
  priceCurrency?: any
}
</script>
