<template lang="pug">
div(:class='{ relative: $storyblok.isEditing.value }')
  ConstructorRecommendations(:key='data.podId' :pod-id='data.podId' :override-padding='true')
    template(#title='{ defaultTitle }')
      div(class='flex flex-wrap justify-between items-center gap-2 gap-y-2 pb-2 md:pb-4 lg:pb-4')
        component(:is='titleComponent' :class='titleClasses' class='font-bold leading-none m-0') {{ title || defaultTitle }}

        UniversalLink(
          v-if='data.linkText && data.linkUrl?.cached_url'
          :link='data.linkUrl.cached_url'
          class='pb-2 md:pb-0 md:pt-2 font-bold underline text-inherit leading-none'
        ) {{ data.linkText }}

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()
const { translateTemplate } = useTemplateTranslator()
const fitmentStore = useFitmentStore()

const { data } = defineProps<{
  data: RecommendationsWidget
}>()

const title = computed(() => {
  if (!data.title && !data.titleFitment) return
  if (data.titleFitment && fitmentStore.fitmentFormatted) return translateTemplate(data.titleFitment)
  if (data.title) return data.title
})

const titleComponent = computed(() => {
  if (data.titleTag) return data.titleTag.toLowerCase()
  return 'p'
})

const titleClasses = computed(() => {
  return `${fontColorClass.value} ${fontSizeClass.value}`
})

const fontColorClass = computed(() => {
  if (data.fontColor) return generateFontColorClass(data.fontColor)
  return 'text-gray-darkest'
})

const fontSizeClass = computed(() => {
  if (data.fontSize) return generateFontSizeClass(data.fontSize)
  return 'text-2xl lg:text-3xl'
})

interface RecommendationsWidget extends Widget {
  podId: string
  title?: string
  titleFitment?: string
  linkText?: string
  linkUrl?: StoryblokLink
  titleTag: 'P' | 'H2' | 'H3'
  fontSize: FontSize
  fontColor: FontColor
}
</script>
