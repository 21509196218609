<template lang="pug">
div(v-if='sections' :class='{ "relative z-[61]": $storyblok.isEditing.value }')
  template(v-for='widget in sections' :key='widget._uid')
    CmsWidget(:data='widget')
</template>

<script setup lang="ts">
const { $storyblok } = useNuxtApp()

const {
  content,
  sectionKey = 'sections',
  forceLazyLoad = false,
  ignoreListener = false,
} = defineProps<{
  content: Content
  sectionKey?: string
  forceLazyLoad?: boolean
  ignoreListener?: boolean
}>()

const contentSections = ref<Sections[]>()

onMounted(() => {
  if (content && !ignoreListener)
    $storyblok.addListener((event) => {
      // @ts-ignore - we can ignore TS here because we know which section keys we pass
      if (event.story.content[sectionKey]) contentSections.value = event.story.content[sectionKey]
    })
})

const sections = computed(() => {
  // @ts-ignore - we can ignore TS here because we know which section keys we pass
  return contentSections.value ? contentSections.value : content[sectionKey]
})
</script>
