import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  "xml:space": "preserve",
  viewBox: "0 0 71.3 72.9"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M71 67.2q0-.15 0 0l-9.4-25.3c-.6-1.8-2.3-3-4.2-3h-6.9c3.6-6.9 6.3-13.4 6.3-17.7C56.9 9.5 47.3 0 35.6 0S14.4 9.5 14.4 21.2c0 4.2 2.7 10.8 6.3 17.7h-6.9c-1.9 0-3.6 1.2-4.1 3L.2 67.2c-.5 1.3-.2 2.8.6 4C1.6 72.3 3 73 4.4 73h62.5c1.4 0 2.7-.7 3.6-1.8.8-1.2 1-2.7.5-4m-53.5-46c0-10 8.1-18.1 18.1-18.1s18.1 8.1 18.1 18.1c0 3.5-2.5 10-7.1 18.5-.3.6-.6 1.1-.9 1.7 0 .1-.1.1-.1.2-.3.6-.6 1.1-1 1.7 0 .1-.1.1-.1.2-1 1.7-1.9 3.3-2.9 4.8-.1.1-.1.2-.2.3-.4.7-.8 1.3-1.2 1.9-.3.5-.7 1.1-1 1.6-.1.1-.1.2-.2.3-.8 1.2-2 1.9-3.4 1.9s-2.7-.7-3.4-1.9q-2.55-4.05-4.8-7.8c-.1-.1-.1-.2-.2-.3-6.2-10.7-9.7-18.9-9.7-23.1m50.4 48.1c-.2.3-.6.5-1 .5H4.4c-.4 0-.8-.2-1-.5s-.3-.7-.2-1.1l9.4-25.3c.2-.5.6-.8 1.2-.8h8.6c2.3 4.2 4.8 8.3 7.2 12.1 1.3 2.1 3.6 3.4 6.1 3.3 2.5 0 4.8-1.3 6.1-3.3 0-.1.1-.2.2-.2.3-.4.5-.8.8-1.3 2.1-3.3 4.3-7 6.2-10.6h8.6c.5 0 1 .3 1.2.9L68 68.2c.1.3.1.7-.1 1.1M35.6 32.6c6.3 0 11.4-5.1 11.4-11.4S41.9 9.8 35.6 9.8s-11.4 5.1-11.4 11.4 5.1 11.4 11.4 11.4m0-19.6c4.5 0 8.3 3.7 8.3 8.2s-3.7 8.2-8.3 8.2-8.3-3.7-8.3-8.2c.1-4.5 3.8-8.2 8.3-8.2" }, null, -1)
  ])))
}
export default { render: render }