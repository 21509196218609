<template lang="pug">
div(class='grid' :class='wrapperClasses')
  template(v-for='(column, index) in data.columns' :key='column._uid')
    CmsWidgetColumnItem(
      :class='columnClasses'
      :column-index='index'
      :data='column'
      :layouts='layouts'
      :mobile-column-layout='data.mobileColumnLayout'
      :tablet-column-layout='data.tabletColumnLayout'
      :desktop-column-layout='data.desktopColumnLayout'
      :space-left='spaceLeft'
    )
      template(#default='{ widget, columnSpaceLeft }')
        slot(:data='widget' :space-left='columnSpaceLeft')
</template>

<script setup lang="ts">
interface ColumnsWidget extends WidgetGutter {
  columns: Widgets[]
  columnBackgroundColor: BackgroundColor
  columnBorderColor: string
  isVerticalAligned: string
  mobileColumnLayout: string
  tabletColumnLayout: string
  desktopColumnLayout: string
}

const { data, spaceLeft } = defineProps<{
  data: ColumnsWidget
  spaceLeft: SpaceLeft
}>()

/**
 * Tailwind Grid Column Whitelist
 *
 * Because we are dynamically creating the class names to reduce the JS complexity
 * we have to list these classes out so they don't get purged during the build process
 *
 * grid-cols-1 md:grid-cols-1 lg:grid-cols-1
 * grid-cols-2 md:grid-cols-2 lg:grid-cols-2
 * grid-cols-3 md:grid-cols-3 lg:grid-cols-3
 * grid-cols-4 md:grid-cols-4 lg:grid-cols-4
 * grid-cols-5 md:grid-cols-5 lg:grid-cols-5
 * grid-cols-6 md:grid-cols-6 lg:grid-cols-6
 * grid-cols-8 md:grid-cols-8 lg:grid-cols-8
 * grid-cols-25-75 md:grid-cols-25-75 lg:grid-cols-25-75
 * grid-cols-33-66 md:grid-cols-33-66 lg:grid-cols-33-66
 * grid-cols-40-60 md:grid-cols-40-60 lg:grid-cols-40-60
 * grid-cols-60-40 md:grid-cols-60-40 lg:grid-cols-60-40
 * grid-cols-70-30 md:grid-cols-70-30 lg:grid-cols-70-30
 * grid-cols-15-70-15 md:grid-cols-15-70-15 lg:grid-cols-15-70-15
 * grid-cols-25-50-25 md:grid-cols-25-50-25 lg:grid-cols-25-50-25
 */

const layouts = {
  '1_COL': {
    class: 'grid-cols-1',
    percent: 100,
  },
  '2_COL': {
    class: 'grid-cols-2',
    percent: 50,
  },
  '2_COL_REVERSE': {
    class: 'grid-cols-2',
    percent: 50,
  },
  '2_COL_25_75': {
    class: 'grid-cols-25-75',
    percent: [25, 75],
  },
  '2_COL_33_66': {
    class: 'grid-cols-33-66',
    percent: [33.3, 66.6],
  },
  '2_COL_40_60': {
    class: 'grid-cols-40-60',
    percent: [40, 60],
  },
  '2_COL_60_40': {
    class: 'grid-cols-60-40',
    percent: [60, 40],
  },
  '2_COL_70_30': {
    class: 'grid-cols-70-30',
    percent: [70, 30],
  },
  '3_COL': {
    class: 'grid-cols-3',
    percent: 33.3,
  },
  '3_COL_15_70_15': {
    class: 'grid-cols-15-70-15',
    percent: [15, 70, 15],
  },
  '3_COL_25_50_25': {
    class: 'grid-cols-25-50-25',
    percent: [25, 50, 25],
  },
  '4_COL': {
    class: 'grid-cols-4',
    percent: 25,
  },
  '5_COL': {
    class: 'grid-cols-5',
    percent: 20,
  },
  '6_COL': {
    class: 'grid-cols-6',
    percent: 16.6,
  },
  '8_COL': {
    class: 'grid-cols-8',
    percent: 12.5,
  },
} as const

const wrapperClasses = computed(() => {
  return [
    getScreenClassPrefix(
      generateColumnLayoutClass(data.mobileColumnLayout),
      generateColumnLayoutClass(data.tabletColumnLayout),
      generateColumnLayoutClass(data.desktopColumnLayout)
    ),
    generateGapClasses(data.mobileGutter, data.tabletGutter, data.desktopGutter),
  ]
})

const columnClasses = computed(() => {
  const css = [generateBackgroundColorClass(data.columnBackgroundColor)]

  if (data.columnBorderColor && data.columnBorderColor !== 'NONE') {
    css.push('border', generateBorderColor(data.columnBorderColor))
  }

  if (data.isVerticalAligned === 'true') {
    css.push('flex', 'flex-col', 'justify-center')
  }

  return css
})

function generateColumnLayoutClass(layout: string) {
  return layouts[layout as keyof ColumnLayouts]?.class || ''
}
</script>
