export function generateLineHeightClass(lineHeight: string) {
  /**
   * Tailwind Line Height Whitelist
   *
   * Because we are dynamically creating the class names to reduce the JS complexity
   * we have to list these classes out so they don't get purged during the build process
   *
   * leading-normal
   * leading-none
   */

  if (!lineHeight) return ''
  return `leading-${lineHeight.toLowerCase()}`
}

export function generateFontSizeClass(fontSize: FontSize) {
  switch (fontSize) {
    case 'XSMALL':
      return 'text-xs'

    case 'SMALL':
      return 'text-sm'

    case 'MEDIUM':
      return 'text-base'

    case 'LARGE':
      return 'text-lg'

    case 'XLARGE':
      return 'text-xl'

    case '2XLARGE':
      return 'text-xl lg:text-2xl'

    case '3XLARGE':
      return 'text-2xl lg:text-3xl'

    case '4XLARGE':
      return 'font-extrabold text-2xl lg:text-4xl'

    case '5XLARGE':
      return 'font-extrabold font-brand text-5xl'

    case '6XLARGE':
      return 'font-extrabold font-brand text-6xl'

    case '7XLARGE':
      return 'font-extrabold font-brand text-7xl'

    case '5XPRIMARY':
      return 'font-extrabold font-brand text-5xl text-primary'

    case '6XPRIMARY':
      return 'font-extrabold font-brand text-6xl text-primary'

    case '7XPRIMARY':
      return 'font-extrabold font-brand text-7xl text-primary'
    default:
      return ''
  }
}
