<template lang="pug">
div(:class='classes')
  CmsWidgetTextElement(v-if='data.text' :data='data.text' :link-color='linkColor')
</template>

<script setup lang="ts">
interface TextWidget extends WidgetAlignment {
  linkColor: FontColor
  backgroundColor: BackgroundColor
  color: FontColor
  lineSpacing: string
  text: {
    content: Record<string, any>
    type: string
  }
  wordWrap: string
}

const { data } = defineProps<{
  data: TextWidget
}>()

const linkColor = computed(() => {
  if (!data.linkColor) return null
  return generateFontColorClass(data.linkColor)
})

const classes = computed(() => {
  const arr = [
    generateBackgroundColorClass(data.backgroundColor),
    generateFontColorClass(data.color),
    generateLineHeightClass(data.lineSpacing),
    generateTextAlignmentClasses(data.mobileAlignment, data.tabletAlignment, data.desktopAlignment),
  ]

  // Whitespace wrapping
  if (data.wordWrap === 'false') arr.push('whitespace-nowrap')

  // Tailwind classes that replace Stylus
  arr.push('[&_h1]:mt-0 [&_h1]:mb-4 [&_h1]:text-inherit [&_h1]:normal-case')
  arr.push('[&_h2]:mt-0 [&_h2]:mb-4 [&_h2]:text-inherit [&_h2]:text-2xl lg:[&_h2]:text-3xl')
  arr.push('[&_h3]:mt-0 [&_h3]:mb-4 [&_h3]:text-inherit [&_h3]:text-xl lg:[&_h3]:text-2xl')
  arr.push('[&_h4]:mt-0 [&_h4]:mb-4 [&_h4]:text-inherit [&_h4]:text-lg lg:[&_h4]:text-xl')
  arr.push('[&_h5]:mt-0 [&_h5]:mb-4 [&_h5]:text-inherit')
  arr.push('[&_h6]:mt-0 [&_h6]:mb-4 [&_h6]:text-inherit')
  arr.push('[&_ol]:list-decimal [&_ol]:pl-4')
  arr.push('[&_ul]:pl-[1.1em] [&_ul]:list-square')
  arr.push('[&_li>p]:mb-2')
  arr.push('[&_p]:mb-4 last:[&_div>p]:mb-0')

  return arr
})
</script>
