// https://github.com/rigor789/vue-scrollto/issues/736
import VueScrollTo from 'vue-scrollto'
import type { ScrollOptions } from 'vue-scrollto'

export default defineNuxtPlugin((nuxtApp) => {
  const { $sitewideConfig } = useNuxtApp()

  function getHeaderOffset() {
    const navBar = document.getElementById('stickyNavbar')
    const NavBounding = navBar?.getBoundingClientRect()
    return NavBounding?.bottom || 0
  }

  if (import.meta.client && $sitewideConfig.header?.stickyEnabled) {
    // Create a style rule to offset scroll targets from the top of the page
    // This is necessary because the sticky header is fixed and will cover the top of the page
    // This only impacts the scroll when the browser handles the scroll due to the #scrollTarget- in the url
    // Example: /p/bakflip-mx4-tonneau-cover/#scrollTarget-install

    useHead({
      style: [
        {
          children: `[id^='scrollTarget-'] {
            scroll-margin-top: ${getHeaderOffset()}px;
          }`,
        },
      ],
    })
  }

  /**
   * Scrolls the browser to an element
   * @param id The selector to scroll to.
   * @example #footer
   */
  const scroll = (id: Element | string, duration = 200, options: ScrollOptions = { easing: [0.12, 0, 0.39, 0] }) => {
    // Do not add Offset if options.container exists (ex carousel)
    if (!options.container && $sitewideConfig.header?.stickyEnabled) {
      if (options.offset) options.offset = -(getHeaderOffset() + Math.abs(options.offset))
      else options.offset = -getHeaderOffset()
    }

    VueScrollTo.scrollTo(id, duration, options)
  }

  nuxtApp.vueApp.directive('scroll-to', {
    mounted(el, binding) {
      const id = binding.value
      el.addEventListener('click', (e: Event) => {
        e.preventDefault()
        scroll(id)
      })
    },
  })

  return {
    provide: {
      scrollTo: scroll,
    },
  }
})
