<template lang="pug">
div(:class='{ relative: $storyblok.isEditing.value }')
  VideoLazyPlayer(
    v-if='rawVideo'
    :is-lazy='true'
    :video-url='rawVideo.filename'
    :poster-image-url='rawVideo.poster'
    :play-on-scroll='true'
    :replay-on-scroll='data.replayOnScroll'
  )

  VideoWistiaThumb(v-else-if='data.mode === "POPUP" && data.type == "WISTIA"' :video='videoData')
  YouTubeThumb(
    v-else-if='data.mode === "POPUP" && data.type === "YOUTUBE"'
    :video='videoData'
    :hi-res='true'
    @click='openLightbox()'
  )
  VideoPlayer(v-else :video='videoData')

  //- For YouTube, we have to make our own lightbox :(
  YouTubeOverlay(v-if='data.mode === "POPUP" && data.type === "YOUTUBE"' :video='videoData')

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const { $device, $storyblok, $overlay } = useNuxtApp()

interface Asset {
  filename: string
}

interface VideoWidget extends Widget {
  type: string
  mode: string
  mobileId: string
  desktopId: string
  mobileVideo: Asset
  tabletVideo: Asset
  desktopVideo: Asset
  mobilePoster: Asset
  tabletPoster: Asset
  desktopPoster: Asset
  replayOnScroll: boolean
}

const { data } = defineProps<{
  data: VideoWidget
}>()

const videoData = computed(() => {
  const video: Video = {
    id: '',
    type: data.type,
  }
  // set a single video ID for backwards compatibility
  if (data.id) video.id = data.id
  // set the mobile and/or desktop video IDs
  if (data.mobileId) video.id = data.mobileId
  if (data.desktopId && $device.value.isDesktop) video.id = data.desktopId
  return video
})

const rawVideo = computed(() => {
  // Early out if we don't have any raw videos to show
  if (!data.mobileVideo?.filename && !data.tabletVideo?.filename && !data.desktopVideo?.filename) return null

  const rawVideo = {
    filename: '',
    poster: '',
  }

  if (data.mobileVideo?.filename) {
    rawVideo.filename = data.mobileVideo.filename
    if (data.mobilePoster?.filename) rawVideo.poster = data.mobilePoster.filename
  }

  if (data.tabletVideo?.filename && !$device.value.isMobile) {
    rawVideo.filename = data.tabletVideo.filename
    if (data.tabletPoster?.filename) rawVideo.poster = data.tabletPoster.filename
  }

  if (data.desktopVideo?.filename && $device.value.isDesktop) {
    rawVideo.filename = data.desktopVideo.filename
    if (data.desktopPoster?.filename) rawVideo.poster = data.desktopPoster.filename
  }

  // Format to use the CDN
  rawVideo.filename = $storyblok.formatSrc(rawVideo.filename)
  rawVideo.poster = $storyblok.formatSrc(rawVideo.poster)

  return rawVideo
})

function openLightbox() {
  $overlay.open(`-${videoData.value.id}`)
}
</script>
