// !!! For this to trigger in local dev, the title has to have RealTruck.com in it, so yeah pretty much only on the homepage !!!!
export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled, disableIntegration } = useUtils()
  if (!isIntegrationEnabled('liveperson')) return
  // Don't load liveperson on the builder page
  // TODO: Find a more elegant way to handle this
  const route = useRoute()
  if (route.path === '/builder/') {
    disableIntegration('liveperson')
    return
  }

  const { load, addCallback } = runOnDemand()
  const { $sitewideConfig } = useNuxtApp()
  const siteId = $sitewideConfig.config.livepersonSiteId

  addCallback(() => {
    loadLivePerson()
  })

  runOnNuxtReady(() => {
    runAfterDelay(() => {
      load()
    }, 15000)
  })

  useRouter().afterEach(() => {
    if (window.lpTag && window.lpTag.newPage) window.lpTag.newPage(document.URL)
  })

  function loadLivePerson() {
    window.lpTag = window.lpTag || {}

    // prettier-ignore
    // eslint-disable-next-line
    // @ts-ignore:
    'undefined'==typeof window.lpTag._tagCount?(window.lpTag={wl:lpTag.wl||null,scp:lpTag.scp||null,site:siteId||'',section:lpTag.section||'',tagletSection:lpTag.tagletSection||null,autoStart:lpTag.autoStart!==!1,ovr:lpTag.ovr||{},_v:'1.10.0',_tagCount:1,protocol:'https:',events:{bind:function(t,e,i){lpTag.defer(function(){lpTag.events.bind(t,e,i)},0)},trigger:function(t,e,i){lpTag.defer(function(){lpTag.events.trigger(t,e,i)},1)}},defer:function(t,e){0===e?(this._defB=this._defB||[],this._defB.push(t)):1===e?(this._defT=this._defT||[],this._defT.push(t)):(this._defL=this._defL||[],this._defL.push(t))},load:function(t,e,i){const n=this;setTimeout(function(){n._load(t,e,i)},0)},_load:function(t,e,i){let n=t;t||(n=this.protocol+'//'+(this.ovr&&this.ovr.domain?this.ovr.domain:'lptag.liveperson.net')+'/tag/tag.js?site='+this.site);const o=document.createElement('script');o.setAttribute('charset',e?e:'UTF-8'),i&&o.setAttribute('id',i),o.setAttribute('src',n),document.getElementsByTagName('head').item(0).appendChild(o)},init:function(){this._timing=this._timing||{},this._timing.start=(new Date).getTime();const t=this;window.attachEvent?window.attachEvent('onload',function(){t._domReady('domReady')}):(window.addEventListener('DOMContentLoaded',function(){t._domReady('contReady')},!1),window.addEventListener('load',function(){t._domReady('domReady')},!1)),'undefined'===typeof window._lptStop&&this.load()},start:function(){this.autoStart=!0},_domReady:function(t){this.isDom||(this.isDom=!0,this.events.trigger('LPT','DOM_READY',{t:t})),this._timing[t]=(new Date).getTime()},vars:lpTag.vars||[],dbs:lpTag.dbs||[],ctn:lpTag.ctn||[],sdes:lpTag.sdes||[],hooks:lpTag.hooks||[],identities:lpTag.identities||[],ev:lpTag.ev||[]},lpTag.init()):window.lpTag._tagCount+=1;
  }

  function triggerChat() {
    const interval = setInterval(() => {
      const el = getClickTarget()
      if (!el) return

      clearInterval(interval)
      el.click()
    }, 500)
  }

  function getClickTarget() {
    // is there a proactive popup? (the normal bubble contains this element so the click has to be moved)
    let el = document.querySelector('[data-lp-event="click"]') as HTMLDivElement
    if (el) return el

    // is there a normal bubble?
    el = document.querySelector('.LPMcontainer') as HTMLDivElement
    if (el) return el
    return null
  }

  return {
    provide: {
      chat: {
        async open() {
          load()
          triggerChat()
        },
      },
    },
  }
})

declare global {
  interface Window {
    lpTag: any
  }
}
