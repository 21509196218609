// The following Tailwind theme is based on the default theme from FormKit and modified to suit our needs.
// When adding a new component style from FormKit, please remove any styles that we do not support.
// Ex: text-gray-600
// https://themes.formkit.com/

export default {
  // Global styles apply to _all_ inputs with matching section keys
  global: {
    fieldset: 'rounded px-2 pb-1',
    help: 'text-xs',
    inner: 'formkit-disabled:cursor-not-allowed formkit-disabled:pointer-events-none',
    input: 'baseInput p-3 h-12 w-full max-w-full border rounded',
    label: 'block mb-1 font-bold text-sm',
    legend: 'font-bold text-sm',
    loaderIcon: 'inline-flex items-center w-4 animate-spin',
    message: 'text-danger mb-1 text-xs',
    messages: 'list-none p-0 mt-1 mb-0',
    outer: 'mb-3 formkit-disabled:opacity-50',
    prefixIcon:
      'w-10 flex self-stretch grow-0 shrink-0 rounded-tl rounded-bl border-r bg-white [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
    suffixIcon:
      'w-7 pr-3 p-3 flex self-stretch grow-0 shrink-0 [&>svg]:w-full [&>svg]:max-w-[1em] [&>svg]:max-h-[1em] [&>svg]:m-auto',
  },

  // Family styles apply to all inputs that share a common family
  'family:box': {
    decorator:
      'border block relative h-5 w-5 mr-2 rounded bg-white peer-checked:ring-action text-transparent peer-checked:text-action',
    decoratorIcon: 'flex p-[3px] w-full h-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2',
    help: 'mb-2 mt-1.5',
    input: 'absolute w-0 h-0 overflow-hidden opacity-0 pointer-events-none peer',
    label: '$reset text-sm select-none',
    wrapper: 'flex items-center mb-1',
  },
  'family:button': {
    input:
      '$reset inline-flex items-center bg-action text-white text-sm font-normal py-3 px-6 rounded formkit-loading:before:w-4 formkit-loading:before:h-4 formkit-loading:before:mr-2 formkit-loading:before:border formkit-loading:before:border-2 formkit-loading:before:border-r-transparent formkit-loading:before:rounded-3xl formkit-loading:before:border-white formkit-loading:before:animate-spin',
    wrapper: 'mb-1',
    prefixIcon: '$reset block w-4 -ml-2 mr-2 stretch',
    suffixIcon: '$reset block w-4 ml-2 stretch',
  },
  'family:dropdown': {
    dropdownWrapper: 'my-2 w-full shadow-lg rounded [&::-webkit-scrollbar]:hidden',
    emptyMessageInner: 'flex items-center justify-center text-sm p-2 text-center w-full [&>span]:mr-3 [&>span]:ml-0',
    inner: 'relative flex rounded mb-1',
    input: 'w-full px-3 py-2',
    listbox: 'bg-white shadow-lg rounded overflow-hidden',
    listboxButton: 'flex w-12 self-stretch justify-center mx-auto',
    listitem: 'pl-7 relative',
    loaderIcon: 'ml-auto',
    loadMoreInner:
      'flex items-center justify-center text-sm p-2 text-center w-full cursor-pointer [&>span]:mr-3 [&>span]:ml-0',
    option: 'p-2.5',
    optionLoading: 'pl-2.5',
    placeholder: 'p-2.5',
    selector: 'flex w-full justify-between items-center min-h-[2.625em] [&u] cursor-default',
    selection: 'flex w-full',
    selectedIcon: 'block absolute top-1/2 left-2 w-3 -translate-y-1/2',
    selectIcon: 'flex box-content w-4 px-2 self-stretch grow-0 shrink-0 [&>svg]:w-[1em] cursor-pointer',
  },
  'family:text': {
    inner: 'flex items-center rounded mb-1',
    input: 'w-full px-3 py-2 text-base',
  },

  // Specific styles apply only to a given input type
  radio: {
    decorator: 'rounded-full',
    decoratorIcon: 'w-5 p-[5px]',
  },
  select: {
    inner: 'flex relative items-center rounded mb-1',
    input:
      'baseSelect relative inline-flex h-12 w-full max-w-full pl-3 justify-items-start align-top items-center leading-6 text-dark bg-white border rounded cursor-pointer',
    selectIcon: 'flex z-[4] p-[3px] shrink-0 w-5 -ml-[1.75em] h-full pointer-events-none [&>svg]:w-[1em]',
    option: 'formkit-multiple:p-3 formkit-multiple:text-sm',
  },
  textarea: {
    inner: 'flex rounded mb-1',
    input:
      'baseTextarea relative block w-full p-3 items-center bg-white border pt-[17px] rounded max-h-[600px] min-h-[120px]',
  },

  // PRO input styles
  autocomplete: {
    closeIcon: 'block grow-0 shrink-0 w-3 mr-3.5',
    inner: '[&>div>[data-value]]:absolute [&>div>[data-value]]:mb-0',
    option: 'grow text-ellipsis',
    selection: 'static flex left-0 top-0 right-0 bottom-0 mt-0 mb-2 rounded',
  },
  dropdown: {
    tagsWrapper: 'max-w-[calc(100%_-_35px)]',
    tags: 'flex items-center flex-wrap gap-1 mx-2 my-1.5',
    tag: 'flex items-center rounded-full text-xs text-black py-1 px-2.5 cursor-default',
    tagLabel: 'px-1',
    selectionsWrapper: 'flex w-[calc(100%_-_35px)] overflow-hidden',
    selections: 'inline-flex items-center px-2.5',
    selectionsItem: 'whitespace-nowrap mr-1 last:mr-0',
    truncationCount:
      'flex items-center whitespace-nowrap justify-center rounded text-white font-bold text-xs px-1 py-0.5',
    removeSelection: 'block w-2.5 my-1 cursor-pointer',
  },
}
