import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 225 221"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", { d: "M191.5 23.6h-18.7l-62.9-.1c-6.3 1-9.6 4.6-10.1 10.9l.6 65.7-31.4.1 44.4 44.6h.4l44.4-44.6-30.4-.1V53c.1-1.4.7-2.2 2-2.6l44.9.1v116.4c-.4 4.7-2.2 7.1-5.2 7.1H56.3c-3.1 0-4.8-2.4-5.2-7.1v-143l-.1-.3H34.1l-1.3.1c-5.8.9-9.1 4.2-10 10l1.3 156.6c.5 6.7 3.9 10.5 10.2 11.5l1.2.2h154.7l1.3-.2c6.3-1 9.7-4.8 10.2-11.5L203 35.2c-.5-6.7-3.9-10.5-10.2-11.5z" }, null, -1)
  ])))
}
export default { render: render }