<template lang="pug">
div
  div(:id='`container-${instanceId}`' class='relative w-full h-0' style='padding-bottom: 56.25%')
    div(:id='instanceId' class='absolute top-0 left-0 w-full h-full bg-[#000]')
      div(v-if='showConsentWarning' class='w-full h-full flex items-center justify-center p-2')
        div(class='text-center text-white')
          | YouTube videos are disabled because you haven't consented to non-essential cookies.
          br
          BaseButton(class='mt-8' label='Consent' to='KETCH' size='SMALL') Update Preferences
</template>
<script setup lang="ts">
// In Nuxt 2, this has a video title and duration, we really shouldn't be using that for this anymore, the experience was crappy.  We should be using either video players
// or video thumbs that spawn popup video players, not inline video players with titles.
// main video = youtube /p/truxedo-sentry-ct-tonneau-cover/
const { video, autoPlay = false } = defineProps<{
  video: Video
  autoPlay?: boolean
}>()
useVideoSchema(video)

const instanceId = ref('')
const { $ketch, $youtube } = useNuxtApp()
const { isIntegrationEnabled } = useUtils()
let videoPlayer: YT.Player | null = null
let playerReady = false
let isInViewport = false
const showConsentWarning = ref(false)

onMounted(() => {
  instanceId.value = 'y' + video.id + Math.random()
  const { observe } = useRunOnVisible()

  // let the DOM update so we can get the element
  nextTick(() => {
    const el = document.getElementById(`container-${instanceId.value}`)
    if (!el) return

    observe(el, loadVideo, pauseVideo, false)
  })

  if (isIntegrationEnabled('ketch')) {
    $ketch.onConsentResolved(() => {
      if ($ketch.hasConsent.value) {
        showConsentWarning.value = false
        $youtube.load()
        if (isInViewport) loadVideo()
      } else {
        showConsentWarning.value = true
      }
    })
  } else {
    $youtube.load()
  }
})

async function loadVideo() {
  isInViewport = true
  if (playerReady || (isIntegrationEnabled('ketch') && !$ketch.hasConsent.value)) return

  await waitUntilTrue(() => window?.YT?.Player !== undefined)

  videoPlayer = new window.YT.Player(instanceId.value, {
    videoId: video.id,
    playerVars: {
      autoplay: autoPlay ? 1 : 0,
      rel: 0,
    },
    events: {
      onReady() {
        playerReady = true
      },
    },
  })
}

function pauseVideo() {
  if (!videoPlayer || !playerReady || typeof videoPlayer?.pauseVideo !== 'function') return

  videoPlayer.pauseVideo()
}
</script>
