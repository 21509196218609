export default defineNuxtPlugin(() => {
  const { $uiEvents } = useNuxtApp()
  const { isProduction } = useUtils()
  const route = useRoute()
  const rootStore = useRootStore()
  const customerManager = useCustomerManager()
  const cart = useCart()
  const notifications = useNotifications()

  const cartId = useCookie('cartId', {
    secure: isProduction(),
    path: '/',
    sameSite: 'lax',
    maxAge: 315360000, // 10 years
  })

  // Get customer id from the url
  const customerId = route.query.customer?.toString()

  // If we have a customer id then attempt to set the active customer
  if (customerId) {
    // Attempt to set the active customer. This should only happen if a sales rep opened this tab
    // from the customer manager.
    customerManager.setActiveCustomer(customerId)
  }

  // If we have an active customer then we are on the customer tab
  // and shouldn't update the cartId cookie
  if (!customerManager.customer.value) {
    watch(
      () => cart.state.value.id,
      (newVal) => {
        cartId.value = newVal
      }
    )
  }

  // Get the third party state that we parsed from the server
  const thirdPartyPayload = useState<AddItemsRequest[] | undefined>('thirdPartyPayload')

  onNuxtReady(async () => {
    // The id of the cart we are trying to init.
    // Use the value from the cartId cookie
    // but if it doesn't exist then init the cart from the existing permId.
    let id = cartId.value || rootStore.permId

    // If we have a customer then use their cart id.
    if (customerManager.customer.value) id = customerManager.customer.value.cartId

    // Init the cart
    // If we couldn't init the cart then we don't need to do anything
    // because new cart actions will make the updates when needed.
    // The cart composable will throw an error if the cart can't be initialized
    // and we log that error within the composable so we can ignore the error here
    await cart.init(id).catch(() => {})

    // If we have a third party state we need to add it to the cart
    if (thirdPartyPayload.value) {
      await cart.addItems(thirdPartyPayload.value)

      // Clean up the third party payload state from the app.
      clearNuxtState('thirdPartyPayload')
    }

    if (route.query.promoCode) {
      await setPromoCode(route.query.promoCode.toString())
    }
  })

  async function setPromoCode(code: string) {
    // Log that an attempt is being made
    $uiEvents.$emit('promoCodeEntered', code)
    try {
      const resp = await cart.addPromoCode(code)
      let message = 'Promo code successfully applied!'
      if (resp.code === 'PROMO_CODE_NOT_APPLIED')
        message = 'Promo code is now active, add the items to your cart for your discount.'

      notifications.addSuccess('Success', message)
    } catch (error) {
      let message = 'Promo code is not valid.'

      if (error.data?.code === 'PROMO_CODE_EXISTS') message = 'Promo code has already been applied.'
      else if (error.data?.code === 'SIMILAR_PROMO_CODE_EXISTS') message = 'Promo code cannot be applied.'

      notifications.addError('Unable to add Promo Code', message)
    }
  }
})
