import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "23 7 181 204"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#fff",
      d: "M26.5 109.3a5.4 5.4 0 0 0-1.7 3.9v90.3c0 1.6.6 2.9 1.7 4.1q1.5 1.5 3.9 1.5h165.8q2.4 0 3.9-1.5a5.7 5.7 0 0 0 1.7-4.1v-90.3c0-1.5-.6-2.8-1.7-3.9a5 5 0 0 0-3.9-1.7h-8.8v-25c0-20.3-7.2-37.8-21.7-52.4a76 76 0 0 0-23.2-16q-13.35-5.7-29.1-5.7C97.65 8.5 93 10.4 84 14.3a72.3 72.3 0 0 0-23.1 16 71.6 71.6 0 0 0-21.7 52.4v25.1h-8.8c-1.5-.2-2.8.4-3.9 1.5m56.7-56.8a40.7 40.7 0 0 1 30.1-12.6c11.9 0 21.9 4.2 30.2 12.6a40.7 40.7 0 0 1 12.6 30.1v25.1H70.6V82.6c0-11.8 4.2-21.8 12.6-30.1"
    }, null, -1)
  ])))
}
export default { render: render }