export default defineNuxtPlugin(() => {
  const { $sitewideConfig, $ketch } = useNuxtApp()
  const { isIntegrationEnabled } = useUtils()

  if (!isIntegrationEnabled('invoca')) return

  const networkId = $sitewideConfig.config.invocaNetworkId
  const tagId = $sitewideConfig.config.invocaTagId
  let hasLoaded = false

  if (isIntegrationEnabled('ketch')) {
    $ketch.onConsentResolved(() => {
      if (!$ketch.hasConsent.value) return
      loadInvoca()
    })
  } else {
    loadInvoca()
  }

  function loadInvoca() {
    if (hasLoaded) return
    hasLoaded = true

    runOnNuxtReady(() => {
      /* eslint-disable */
      // prettier-ignore
      (function(i,n,v,o,c,a) { i.InvocaTagId = o; var s = n.createElement('script'); s.type = 'text/javascript';
          s.async = true; s.src = ('https:' === n.location.protocol ? 'https://' : 'http://' ) + v;
          var fs = n.getElementsByTagName('script')[0]; fs.parentNode?.insertBefore(s, fs);
          })(window, document, 'solutions.invocacdn.com/js/invoca-latest.min.js', `${networkId}/${tagId}`);
      /* eslint-enable */
    })
  }

  function changePhone() {
    if (window.Invoca && typeof window.Invoca.shouldRun === 'function' && window.Invoca.shouldRun()) {
      if (window.Invoca.PNAPI && typeof window.Invoca.PNAPI.run === 'function') window.Invoca.PNAPI.run()
    }
  }

  return {
    provide: {
      invoca: {
        changePhone,
      },
    },
  }
})

declare global {
  interface Window {
    InvocaTagId: string
    Invoca: {
      shouldRun(): boolean
      PNAPI: {
        run(): void
      }
    }
  }
}
