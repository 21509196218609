import type { Experiment } from '@amplitude/experiment-js-client'

export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled, disableIntegration } = useUtils()
  const { $ketch } = useNuxtApp()

  if (!isIntegrationEnabled('cognito') || !isIntegrationEnabled('segment')) disableIntegration('amplitude')
  if (!isIntegrationEnabled('amplitude')) return

  // This plugin is set to 02.client.ts because it is dependant on both $segment and $cognito.
  const { $sitewideConfig, $uiEvents, $cognito, $segment } = useNuxtApp()
  const log = useLogger('AMPLITUDE')
  let experiment: ReturnType<typeof Experiment.initialize>
  const isAmplitudeLoaded = ref(false)

  const loadAmplitude = async () => {
    if (isAmplitudeLoaded.value) return

    try {
      const { Experiment } = await import('@amplitude/experiment-js-client')
      // Initialize Amplitude Experiment
      experiment = Experiment.initialize($sitewideConfig.config.amplitudeKey, {
        automaticExposureTracking: false,
        fetchOnStart: true,
        exposureTrackingProvider: {
          track: (exposure) => {
            // only track the exposure if they are in a Variant
            if (!exposure.variant) return
            $uiEvents.$emit('amplitudeExposure', exposure)
          },
        },
      })

      // Start the experiment
      const user = await getUser()
      await experiment.start(user)

      // Fetch the experiment again any time they login or out
      $uiEvents.$on('cognitoSignUp', async (userId) => await fetchExperiment(userId))
      $uiEvents.$on('cognitoSignIn', async () => await fetchExperiment())

      // It is now okay to use Amplitude
      isAmplitudeLoaded.value = true
    } catch (error) {
      log.error('initialize', { error })
    }
  }

  // Load and start Amplitude if we have consent
  if (isIntegrationEnabled('ketch')) {
    $ketch.onConsentResolved(() => {
      if (!$ketch.hasConsent.value) return
      loadAmplitude()
    })
  } else {
    loadAmplitude()
  }

  const getVariant = async (experimentId: string) => {
    return await experiment.variant(experimentId)
  }

  const exposure = async (experimentId: string) => {
    return await experiment.exposure(experimentId)
  }

  const getUser = async (userId?: string) => {
    if (!userId) {
      const user = await $cognito.getUserAttributes()
      userId = user?.id
    }

    const deviceId = await $segment.getAnonId()

    // Testing Locally: 'staging-test-experiment'
    // const deviceId = 'b63739e3-df71-4462-b46a-4d1f3a28fe5a' // In Treatment
    // const deviceId = '9f63ebce-e647-498e-ad8e-2b195507eec2' // In Control

    return {
      user_id: userId,
      device_id: deviceId,
    }
  }

  const fetchExperiment = async (userId?: string) => {
    try {
      const user = await getUser(userId)
      await experiment.fetch(user)
    } catch (error) {
      log.error('fetch', { error })
    }
  }

  return {
    provide: {
      amplitude: {
        isLoaded: isAmplitudeLoaded,
        getVariant,
        exposure,
      },
    },
  }
})
