import type { Variant } from '@amplitude/experiment-js-client'

export default function () {
  const { isIntegrationEnabled } = useUtils()
  const log = useLogger('USE_AMPLITUDE')
  const { $amplitude } = useNuxtApp()
  const { observe } = useRunOnVisible({ threshold: 0.8 })
  const trackWhenObserved = ref(false)
  const observeRef = useCurrentElement()
  // The experiment we want to run once ampplitude is loaded
  let deferredExperiment: Experiment | undefined = undefined

  function onReady(experiment: Experiment, trackWhenVisible = false) {
    if (!isIntegrationEnabled('amplitude')) return
    trackWhenObserved.value = trackWhenVisible
    // If this useAmplitude instance is created before Amplitude is loaded, defer the experiment
    // otherwise just run the experiment
    if (!$amplitude?.isLoaded?.value) deferredExperiment = experiment
    else runExperiment(experiment)
  }

  // Defer the experiment until Amplitude is loaded
  watch(
    () => $amplitude && $amplitude.isLoaded.value,
    (newVal) => {
      if (newVal && deferredExperiment) {
        runExperiment(deferredExperiment)
      }
    }
  )

  async function runExperiment(experiment: Experiment) {
    const { experimentId, callback } = experiment

    try {
      const variant = await $amplitude.getVariant(experimentId)
      // Early out if not in the test
      if (!variant.value) return

      // Track the exposure
      // If observeRef is set, track the exposure only when the ref is visible
      if (trackWhenObserved.value && observeRef.value instanceof Element)
        observe(
          observeRef.value,
          () => track(experimentId),
          () => {}
        )
      else track(experimentId)

      // Run the callback
      if (callback) callback(variant)
    } catch (error) {
      log.error('get variant', { error })
    }
  }

  function track(experimentId: string) {
    if (!isIntegrationEnabled('amplitude')) return

    try {
      $amplitude.exposure(experimentId)
    } catch (error) {
      log.error('track exposure', { error })
    }
  }

  return {
    onReady,
  }

  interface Experiment {
    experimentId: string
    callback?: (variant: Variant) => void
  }
}
