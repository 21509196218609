<template lang="pug">
div(class='relative' :style='containerStyle')
  VideoWistiaPlayerBorderless(v-if='video' :video='video')

  //- Shade for storyblok editor to prevent clicks
  div(v-if='$storyblok.isEditing.value' class='absolute inset-0 z-50')
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()
interface VideoBannerWidget extends Widget {
  desktopSize: string
  desktopId: string
  mobileId: string
}

const { data } = defineProps<{
  data: VideoBannerWidget
}>()

const containerStyle = computed(() => {
  const style = {
    aspectRatio: '768/465',
  }
  if (!$device.value.isMobile) style.aspectRatio = data.desktopSize === 'BANNER' ? '1920/450' : '1920/600'

  return style
})

const video = computed(() => {
  if (!data.mobileId || !data.desktopId) return false
  const id = $device.value.isMobile ? data.mobileId : data.desktopId
  return {
    id,
  }
})
</script>
