export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled, disableIntegration } = useUtils()
  if (!isIntegrationEnabled('wisepops')) return

  // Don't load wisepops on the builder page
  // TODO: Find a more elegant way to handle this
  const route = useRoute()
  if (route.path === '/builder/') {
    disableIntegration('wisepops')
    return
  }

  const { $sitewideConfig, $uiEvents, $ketch } = useNuxtApp()
  const { onRouteChanged } = useRunOnRouteChanged()

  onRouteChanged(() => {
    if (typeof window.wisepops === 'function') window.wisepops('pageview')
  })

  function loadWisepops() {
    runOnNuxtReady(() => {
      runAfterDelay(() => {
        /* eslint-disable */
        // prettier-ignore
        // @ts-ignore
        (function(W,i,s,e,P,o,p){W['WisePopsObject']=P;W[P]=W[P]||function(){ (W[P].q=W[P].q||[]).push(arguments)},W[P].l=1*new Date();o=i.createElement(s), p=i.getElementsByTagName(s)[0];o.async=1;o.src=e;p.parentNode.insertBefore(o,p) })(window,document,'script',`//loader.wisepops.com/get-loader.js?v=1&site=${$sitewideConfig.config.wisepopsSiteId}`,'wisepops');

        window.wisepops('listen', 'after-form-submit', function (event: any) {
          const email = event.target.elements['email']?.value || null
          const isValidEmail = /^.+\@.+\..+$/.test(email)

          if (!email || !isValidEmail) return

          // Default to wisepops to catch if any are missing formName
          const formName = event.target.elements['formName']?.value || 'wisepops'
          $uiEvents.$emit('newsletterSubscribed', { formName, email })
        })
      }, 10000)
    })
  }

  if (isIntegrationEnabled('ketch')) {
    $ketch.onConsentResolved(() => {
      if (!$ketch.hasConsent.value) return
      loadWisepops()
    })
  } else {
    loadWisepops()
  }
})

declare global {
  interface Window {
    wisepops: any
  }
}
