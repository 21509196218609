<template lang="pug">
VideoWistiaPlayer(v-if='video.type.toLowerCase() == "wistia"' :video='video' :auto-play='autoPlay')
YouTubePlayer(v-else :video='video' :auto-play='autoPlay')
</template>
<script setup lang="ts">
// This comp represents a responsive video player that takes up as much room as you give it.
// It's a proxy comp in that you give it a vid obj and it will render the appropriate player

const { video, autoPlay = false } = defineProps<{ video: Video; autoPlay?: boolean }>()
if (video.type?.toLocaleLowerCase() === 'wistia') useWistia().loadVideo(video.id)
</script>
