<template lang="pug">
ProtexWrapper(v-if='isIntegrationEnabled("protex")')
  template(v-for='widget in data.widgets')
    slot(:data='widget' :space-left='spaceLeft')
</template>

<script lang="ts" setup>
const { isIntegrationEnabled } = useUtils()
defineProps<{
  data: Widgets
  spaceLeft?: SpaceLeft
}>()
</script>
