<template lang="pug">
CmsBackgroundImage(
  :uid='data._uid'
  :mobile-filename='data.mobileBackgroundImage?.filename'
  :tablet-filename='data.tabletBackgroundImage?.filename'
  :desktop-filename='data.desktopBackgroundImage?.filename'
  :space-left='spaceLeft'
  class='flex-1 relative'
  :class='sizeClasses'
)
  div(class='absolute inset-0 bg-[#000000]' :style='shadeStyle')

  UniversalLink(
    :link='data.link'
    tracking-event-type='ImageCard'
    :open-new-window='data.linkTarget === "NEW_WINDOW"'
    class='block relative text-inherit'
    :class='paddingClasses'
  )
    div(class='relative flex flex-col' :class='wrapperClasses')
      div
        template(v-for='widget in data.widgets')
          slot(:data='widget')
</template>

<script setup lang="ts">
const { $device } = useNuxtApp()

interface ImageCardWidget extends Widgets {
  button: any
  link: string
  linkTarget: string
  position: VerticalAlignment
  size: string
  text: any
  mobileBackgroundImage: { filename: string }
  tabletBackgroundImage: { filename: string }
  desktopBackgroundImage: { filename: string }
  shadeOpacity: string
  mobileShadeOpacity: string
  tabletShadeOpacity: string
  desktopShadeOpacity: string
}

const { data, spaceLeft } = defineProps<{
  data: ImageCardWidget
  spaceLeft: SpaceLeft
}>()

const sizeClasses = computed(() => {
  return generateSizeClass()
})

const paddingClasses = computed(() => {
  const padding = generatePaddingClasses(data.mobilePadding, data.tabletPadding, data.desktopPadding)
  if (padding) return padding
})

const wrapperClasses = computed(() => {
  return [generateSizeClass(), generateFlexAlignmentClass(data.position)]
})

const shadeStyle = computed(() => {
  const style: Record<string, any> = {}

  let opacity: any = null

  // Backwards compatability
  if (data.shadeOpacity && data.shadeOpacity !== '') opacity = parseInt(data.shadeOpacity)

  if (data.mobileShadeOpacity && data.mobileShadeOpacity !== '') opacity = parseInt(data.mobileShadeOpacity)

  if (data.tabletShadeOpacity && data.tabletShadeOpacity !== '' && !$device.value.isMobile)
    opacity = parseInt(data.tabletShadeOpacity)

  if (data.desktopShadeOpacity && data.desktopShadeOpacity !== '' && $device.value.isDesktop)
    opacity = parseInt(data.desktopShadeOpacity)

  if (!isNaN(opacity)) {
    style.opacity = Math.min(Math.max(opacity * 0.01, 0), 1)
  }

  return style
})

function generateSizeClass() {
  switch (data.size) {
    case 'SMALL':
      return 'min-h-[280px] lg:min-h-[420px]'

    case 'MEDIUM':
      return 'min-h-[350px] lg:min-h-[460px]'

    case 'LARGE':
      return 'min-h-[400px] lg:min-h-[500px]'
  }
}
</script>
