import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  xmlns: "http://www.w3.org/2000/svg",
  viewBox: "0 0 70.9 71.1"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      fill: "#6c6c6c",
      d: "M69.7 63.7a5 5 0 0 1-1.5 3.6 5 5 0 0 1-3.6 1.6c-1.5 0-2.7-.6-3.6-1.6L47.2 53.6a27.8 27.8 0 0 1-36-3.3 28.3 28.3 0 0 1-6.1-9 27.8 27.8 0 0 1-2.2-11 27.8 27.8 0 0 1 8.2-20c2.5-2.6 5.6-4.6 9-6a27.8 27.8 0 0 1 11-2.3 27.8 27.8 0 0 1 20 8.3 28.3 28.3 0 0 1 3.3 36l13.8 13.8a5 5 0 0 1 1.5 3.6M44 43c3.5-3.5 5.2-7.8 5.2-12.7 0-5-1.7-9.2-5.2-12.7a17.3 17.3 0 0 0-13-5.3c-5 0-9.2 1.7-12.7 5.3A17.3 17.3 0 0 0 13 30.3c0 5 1.8 9.2 5.3 12.7s7.8 5.3 12.7 5.3c5 0 9.2-1.8 12.8-5.3z"
    }, null, -1)
  ])))
}
export default { render: render }