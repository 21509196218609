<template lang="pug">
div
  div(class='relative group' :class='{ "hide-scroll": arrowPosition === "BOTTOM" }')
    div(
      ref='items'
      class='snap-mandatory snap-x lg:snap-none [&>*]:snap-start [&>*]:flex-none lg:[&>*]:snap-align-none flex flex-nowrap overflow-x-scroll lg:overflow-x-hidden overflow-y-hidden'
      :class='containerClass'
    )
      slot

    div(v-if='arrowPosition === "BOTTOM"')
      div(v-if='canScroll' :style='arrowWidthStyle' class='pt-5 w-full flex justify-between items-center')
        button(
          type='button'
          title='previous'
          aria-label='Previous'
          :disabled='!isLooped && scrolledLeft'
          class='hidden sm:block pointer-events-auto'
          @click='scrollLeft'
        )
          ArrowLeft(:class='{ "text-gray-light": !isLooped && scrolledLeft }' class='w-6 h-6' alt='Last page')

        div(class='relative mx-3 w-full bg-gray-light h-[3px]')
          div(:style='tickStyle' class='absolute bg-black h-[3px] transition-all duration-300')

        button(
          type='button'
          title='next'
          aria-label='Next'
          :disabled='!isLooped && scrolledRight'
          class='hidden sm:block pointer-events-auto'
          @click='scrollRight'
        )
          ArrowRight(:class='{ "text-gray-light": !isLooped && scrolledRight }' class='w-6 h-6' alt='Next page')

    div(
      v-else
      class='absolute w-full top-0 bottom-0 justify-between items-center pointer-events-none transition-opacity'
      :class='arrowModeClass'
    )
      button(
        v-if='canScroll'
        type='button'
        title='previous'
        class='py-6 px-2 pointer-events-auto'
        :class='arrowButtonClass.left'
        @click='scrollLeft'
      )
        img(v-if='arrowStyle === "THIN"' src='/images/carousel-arrow.svg' class='w-5' alt='Last image')
        img(v-else src='@/assets/chevronLeft.svg' class='w-6 h-6' alt='Last page')
      button(
        v-if='canScroll'
        type='button'
        title='next'
        class='py-6 px-2 pointer-events-auto'
        :class='arrowButtonClass.right'
        @click='scrollRight'
      )
        img(v-if='arrowStyle === "THIN"' src='/images/carousel-arrow.svg' class='w-5 rotate-180' alt='Next image')
        img(v-else src='@/assets/chevronRight.svg' class='w-6 h-6' alt='Next page')
</template>

<script setup lang="ts">
import ArrowLeft from '@/assets/chevronLeft.svg?component'
import ArrowRight from '@/assets/chevronRight.svg?component'

const { $device } = useNuxtApp()

const items = ref<HTMLElement | null>(null)
const scrolledLeft = ref(true)
const scrolledRight = ref(false)
const canScroll = ref(true)

const totalPages = ref(0)
const currentPos = ref(0)
const itemsPerPage = ref(0)
const arrowWidthStyle = ref('')
const tickStyle = ref('')
const isScrollTo = ref(false)

const {
  arrowMode = 'HOVER',
  isLooped = false,
  containerClass = '',
  scrollTo = 0,
  arrowStyle = 'DEFAULT',
  arrowPosition = 'INLINE',
  arrowWidth = 'DEFAULT',
} = defineProps<{
  arrowMode?: 'NONE' | 'HOVER' | 'ALWAYS'
  isLooped?: boolean
  containerClass?: string
  scrollTo?: number
  arrowStyle?: 'DEFAULT' | 'THIN'
  arrowPosition?: 'INLINE' | 'BOTTOM'
  arrowWidth?: 'FULL' | 'DEFAULT'
}>()

const arrowModeClass = computed(() => {
  if (arrowMode === 'NONE') return 'hidden'
  else if (arrowMode === 'ALWAYS') return 'flex'

  // Default 'HOVER' state
  return 'hidden opacity-0 lg:flex lg:group-hover:opacity-100'
})

const arrowButtonClass = computed(() => {
  const baseCarouselClasses = 'bg-white text-black shadow-md md:shadow-none'
  return {
    left: [
      scrolledLeft.value && !isLooped ? 'opacity-25' : 'opacity-90',
      arrowStyle === 'DEFAULT' ? `rounded-r ${baseCarouselClasses}` : '',
    ],
    right: [
      scrolledRight.value && !isLooped ? 'opacity-25' : 'opacity-90',
      arrowStyle === 'DEFAULT' ? `rounded-l ${baseCarouselClasses}` : '',
    ],
  }
})

watch(
  () => scrollTo,
  (newIndex: number) => {
    if (items.value) scroll(items.value.children[newIndex], 0)
  }
)

// We only need to watch for resize changes if we are using the bottom arrow position
if (arrowPosition === 'BOTTOM') {
  // We need to watch the items div for width changes and recalculate stuff,
  // otherwise funky things happen when you resize the window
  useResizeObserver(items, () => {
    totalPages.value = getNumberOfPages()
    arrowWidthStyle.value = getArrowControlWidthStyle()
    tickStyle.value = getTickStyle()
  })
}

onMounted(() => {
  checkCanScroll()

  items.value?.addEventListener('scroll', () => {
    checkScrollPosition()
  })

  // We only need to set up the tick and total pages if we are using the bottom arrow position
  if (arrowPosition === 'BOTTOM') {
    totalPages.value = getNumberOfPages()
    arrowWidthStyle.value = getArrowControlWidthStyle()
    tickStyle.value = getTickStyle()
  }
})

function updateCurrentPos(pos: number) {
  // Early out: We only need to update the position and tick if we are using the bottom arrow position
  if (arrowPosition !== 'BOTTOM') return

  currentPos.value = pos
  tickStyle.value = getTickStyle()
}

function getArrowControlWidthStyle() {
  if (!items.value || !items.value.children[0] || $device.value.isMobile || arrowWidth === 'FULL') return ''
  return `max-width: ${items.value.children[0].clientWidth}px`
}

function getTickStyle() {
  const tickWidth = (100 / items.value.children.length) * itemsPerPage.value
  const tickPosition = (100 / items.value.children.length) * currentPos.value
  return `width: ${tickWidth}%; left: ${tickPosition}%`
}

function getNumberOfPages() {
  if (!items.value) return

  itemsPerPage.value = getItemsPerPage()
  const totalItems = items.value.children.length
  const pages = Math.ceil(totalItems / itemsPerPage.value)
  return pages
}

function getItemsPerPage() {
  const itemWidth = items.value.children[0].getBoundingClientRect().width
  const containerWidth = items.value.clientWidth
  const perPage = Math.floor(containerWidth / itemWidth)

  // Ensure we always return at least 1 item per page
  return perPage > 0 ? perPage : 1
}

function scrollLeft() {
  if (!isLooped && scrolledLeft.value) return

  if (!items.value || items.value.children.length === 0) return

  const children = items.value.children

  if (isLooped && items.value.scrollLeft === 0) {
    updateCurrentPos(items.value.children.length - itemsPerPage.value)
    scroll(children[children.length - 1])
    return
  }

  const target = getScrollTarget(false)
  if (!target) return

  // Make sure the previous position is not less than 0 for edge cases
  const prevPos = currentPos.value - itemsPerPage.value
  updateCurrentPos(prevPos < 0 ? 0 : prevPos)

  scroll(target)
}

function scrollRight() {
  if (!isLooped && scrolledRight.value) return

  if (!items.value || items.value.children.length === 0) return

  const children = items.value.children

  if (isLooped && items.value.scrollLeft + items.value.clientWidth === items.value.scrollWidth) {
    updateCurrentPos(0)
    scroll(children[0])
    return
  }

  const target = getScrollTarget()
  if (!target) return

  // Make sure the next position is not greater than the last possible position
  const posLength = items.value.children.length - itemsPerPage.value
  const nextPos = currentPos.value + itemsPerPage.value
  updateCurrentPos(nextPos > posLength ? posLength : nextPos)

  scroll(target)
}

function scroll(target: Element, speed = 500) {
  const { $scrollTo } = useNuxtApp()
  if (!items.value) return

  const options = {
    container: items.value,
    x: true,
    y: false,
    force: true,
    easing: 'ease-in-out',
    onStart: () => {
      isScrollTo.value = true
    },
    onDone: () => {
      isScrollTo.value = false
    },
  }

  $scrollTo(target, speed, options)
}

// Checks right scroll by default
function getScrollTarget(scrollingRight = true) {
  let firstClipped

  if (!items.value) return

  for (let i = 0; i < items.value.children.length; i++) {
    const elementBoundingRect = items.value.children[i].getBoundingClientRect()
    const parentBoundingRect = items.value.children[i].offsetParent.getBoundingClientRect()

    if (scrollingRight && elementBoundingRect.right > parentBoundingRect.right) {
      firstClipped = items.value.children[i]
      break
    } else if (
      !scrollingRight &&
      elementBoundingRect.left <= parentBoundingRect.left &&
      elementBoundingRect.left >= parentBoundingRect.left - parentBoundingRect.width
    ) {
      firstClipped = items.value.children[i]
      break
    }
  }

  return firstClipped
}

function checkScrollPosition() {
  if (!items.value) return

  scrolledLeft.value = false
  scrolledRight.value = false

  const scrollLeft = items.value.scrollLeft
  const scrollWidth = items.value.scrollWidth

  // Scrolled all the way to the left
  if (scrollLeft === 0) {
    updateCurrentPos(0)
    scrolledLeft.value = true
    return
  }

  // Scrolled all the way to the right
  if (scrollLeft === scrollWidth - (items.value.offsetParent?.clientWidth || 0)) {
    updateCurrentPos(items.value.children.length - itemsPerPage.value)
    scrolledRight.value = true
    return
  }

  // We do not want to update the current position if we are in a scrollTo animation / its already handled
  if (isScrollTo.value) return

  const posBreakpoint = scrollWidth / items.value.children.length
  const pos = Math.floor(scrollLeft / posBreakpoint)
  updateCurrentPos(pos)
}

function checkCanScroll() {
  if (!items.value || items.value.children.length === 0) return

  const lastItem = items.value.lastElementChild

  if (!lastItem) return

  if (!lastItem.offsetParent) return

  const lastItemBounds = lastItem.getBoundingClientRect()
  const parentBounds = lastItem.offsetParent.getBoundingClientRect()

  canScroll.value = lastItemBounds.right > parentBounds.right
}
</script>
