<template lang="pug">
Overlay(unique-key='FitmentModal' modal-width='WIDE' title='Select Your Vehicle' @closed='close')
  template(v-if='showMobileLogoBanner && $device.isMobile' #topBar)
    div(class='bg-black w-full flex justify-between')
      img(src='/images/rt-logo.svg' alt='' class='py-2 pl-3 h-8 w-auto' width='198' height='32')
  div(data-testid='fitmentModal')
    client-only
      FitmentSelect(
        v-for='select in displayStore.modalRequiredSelectTypes'
        :key='select.type'
        data-testid='requiredSelect'
        :select='select'
        :current-value='select.currentValue'
        class='mb-3'
        @changed='fitmentDisplay.selectChanged'
      )

  template(#footer)
    div(v-if='displayStore.modal.isFitsMode' class='text-center')
      BaseButton(:is-disabled='!fitmentDisplay.allDoneSelecting.value' size='LARGE' @click='viewFits') View Items That Fit

    template(v-else)
      div(class='flex pb-6')
        div(class='flex-1 pr-3')
          BaseButton(color='GRAY' class='w-full' data-testid='fitmentClear' @click='clear') Clear

        div(class='flex-1 pl-3')
          BaseButton(
            :is-disabled='!fitmentDisplay.allDoneSelecting.value'
            class='w-full'
            data-testid='fitmentSave'
            @click='save'
          ) Save
</template>

<script setup lang="ts">
import type { RouteLocationRaw } from 'vue-router'
const displayStore = useFitmentDisplayStore()
const fitmentDisplay = useFitmentDisplay()
const fitmentStore = useFitmentStore()
const routeBuilder = useRouteBuilder()
const route = useRoute()
const { $device, $overlay } = useNuxtApp()
const amplitude = useAmplitude()
const { isIntegrationEnabled } = useUtils()
const showMobileLogoBanner = ref(false)

// Experiment: On the Home Page auto load the fitment modal if we don't have full fitment
if (route.name === 'home' && !displayStore.hasFullFitment) {
  amplitude.onReady({
    experimentId: 'forced-fitment',
    callback: (variant) => {
      if (variant.value !== 'treatment') return

      showMobileLogoBanner.value = true
      displayStore.showFitmentModal()
    },
  })
}

onMounted(() => {
  fitmentDisplay.layoutPosition.value = 'modal'
})

function save() {
  fitmentDisplay.saveFitment()
  // Mobile redirects no matter what with ?mod but desktop needs some help closing the modal
  if ($device.value.isDesktop) $overlay.close()
}

function viewFits() {
  fitmentDisplay.saveFitment()
  navigateTo({
    name: 'mmy',
    params: {
      makeSlug: fitmentStore.$state.makeSlug,
      modelSlug: fitmentStore.$state.modelSlug,
      year: fitmentStore.$state.year,
    },
  })
}

function close() {
  displayStore.resetFitmentModal()

  if (isIntegrationEnabled('amplitude')) {
    // We only show the banner when auto triggered as part of the test
    // so reset it back to false.
    showMobileLogoBanner.value = false
  }

  // If we are in the isSameDayShippingMode mode and we don't have full fitment
  if (displayStore.modal.isSameDayShippingMode && !displayStore.hasFullFitment) {
    const query = { ...route.query }

    if (query.sameDayShipping) {
      // This removes the sameDayShipping stuff for the catalog page.
      delete query.sameDayShipping

      // Push to new route as Replace
      navigateTo({ ...route, query }, { replace: true })
      return
    }
  }
}

function clear() {
  displayStore.clearFitment()

  if (route.name === 'mmy') {
    navigateTo('/')
    return
  }

  const newRoute: RouteLocationRaw = {
    name: route.name || '',
    params: Object.assign({}, route.params),
    query: Object.assign({}, route.query),
  }

  if (newRoute.name === 'search') newRoute.query = { ...newRoute.query, z: Date.now().toString() }

  if (newRoute.name && ['product-mmy', 'sku'].includes(newRoute.name.toString())) {
    newRoute.query = { z: Date.now().toString() }
  }

  if (newRoute.query?.mod) delete newRoute.query.mod
  if (newRoute.query?.sameDayShipping) delete newRoute.query.sameDayShipping
  navigateTo(routeBuilder.formatRoute(newRoute))
}
</script>
