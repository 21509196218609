import mitt from 'mitt'

export default defineNuxtPlugin(() => {
  const { isIntegrationEnabled } = useUtils()
  if (!isIntegrationEnabled('ketch')) return

  const { $sitewideConfig } = useNuxtApp()
  const logger = useLogger('ketch')
  const emitter = mitt()
  const ketchCode = $sitewideConfig.config.ketchCode
  let consentResolved = false
  const hasConsent = ref(false)
  let hasUserConsentedBefore = false

  /* eslint-disable */
  // prettier-ignore
  // @ts-ignore
  !function(){window.semaphore=window.semaphore||[],window.ketch=function(){window.semaphore.push(arguments)};var e=document.createElement("script");e.type="text/javascript",e.onError=onKetchError,e.src="https://global.ketchcdn.com/web/v3/config/realtruck/" + ketchCode + "/boot.js",e.defer=e.async=!0,document.getElementsByTagName("head")[0].appendChild(e)}();
  /* eslint-enable */

  window.ketch('on', 'consent', (consent: any) => {
    hasConsent.value = consent?.purposes?.['optional'] || false
    emitter.emit('consentResolved')
    consentResolved = true
  })

  window.ketch('on', 'userConsentUpdated', (consent: any) => {
    if (hasUserConsentedBefore && !consent?.purposes?.optional) window.location.reload()
    hasUserConsentedBefore = true
  })

  function onKetchError(error: Error) {
    hasConsent.value = false
    emitter.emit('consentResolved')
    logger.error('Ketch load error', error)
  }

  function showExperience() {
    window.ketch('showExperience')
  }

  function onConsentResolved(callback: () => void) {
    if (consentResolved) callback()
    emitter.on('consentResolved', callback)
  }

  return {
    provide: {
      ketch: {
        showExperience,
        hasConsent: readonly(hasConsent),
        onConsentResolved,
      },
    },
  }
})
